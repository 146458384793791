<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '80vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>
  
    <div v-else>
      <CCardHeader>
        Creatives Screening
        <CButton
          class="ml-2"
          size="sm"
          color="info"
          variant="outline"
          @click="onChangeAutoReload"
        >
          Auto Reload
        </CButton>
      </CCardHeader>
  
      <CCardBody>
        <CDataTable
          small
          fixed
          border
          sorter
          column-filter
          :items="formattedPendingCreatives"
          :fields="fields"
          :items-per-page="10"
          :pagination="{align: 'center'}"
        >
          <template #id="{item}">
            <td>
              {{ item.id }}
              <a
                href
                class="ml-1"
                @click.prevent="
                  copyIdToClipboard(getSafe(() => item.id))
                "
              ><CIcon
                name="cil-copy"
              /></a> 
            </td>
          </template>

          <template #accountName="{item}">
            <td>
              <router-link
                :to="{ name: 'SellerAccountDetails', params: { id: getSafe(() => item.account.id)} }"
                target="_blank"                 
              >
                {{ getSafe(() => item.account.name) }}
              </router-link>

              <a
                href
                class="ml-1"
                @click.prevent="
                  copyAccountIdToClipboard(getSafe(() => item.account.id))
                "
              ><CIcon
                name="cil-copy"
              /></a>            
            </td>
          </template>

          <template #name="{item}">
            <td>
              {{ item.name }}
            </td>
          </template>

          <template #externalId="{item}">
            <td>
              {{ item.externalId }}
            </td>
          </template>

          <template #cmsId="{item}">
            <td>
              {{ item.cmsId }}
            </td>
          </template>
          
          <template #categoryName="{item}">
            <td>
              {{ getSafe(() => item.category.name) }}
            </td>
          </template>

          <template #trafficSourceName="{item}">
            <td>
              {{ getSafe(() => item.trafficSource.name) }}
            </td>
          </template>

          <template #countryId="{item}">
            <td>
              {{ getSafe(() => item.country.id) }}
            </td>
          </template>

          <template #languageId="{item}">
            <td>
              {{ getSafe(() => item.language.id) }}
            </td>
          </template>

          <template #Actions="{item}">
            <td>
              <CButton
                color="info"
                size="sm"
                :to="`/dashboard/creatives/review/${item.id}`"
              >
                View Creative
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </div>
  </CCard>
</template>
  
  <script>

  import { mapActions, mapState } from 'vuex'  
  import { showSuccessMessage } from '@/notification-utils'
  import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
  import { dateTimeFormat } from '@/utils'
 
  const AUTO_RELOAD_INTERVAL = 120 * 1000 // 2 mins interval
  
  let autoReloadTimer
  
  export default {
    name: 'ScreeningPendingCreatives',
  
    title: 'Creatives Screening',  

    mixins: [copyToClipboardMixin],
    
    data() {
      return {
        type: '',
        isLoading: false,
        autoReload: false
      }
    },
  
    computed: {
      ...mapState('creative', ['screeningPendingCreatives']),
  
      fields () {
        return [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'accountName', label: 'Account', sortable: true },
          { key: 'externalId', label: 'External ID', sortable: true },        
          { key: 'cmsId', label: 'SMID (CMS Id)', sortable: true },        
          { key: 'categoryName', label: 'Category', sortable: true },               
          { key: 'trafficSourceName', label: 'Traffic Source', sortable: true },               
          { key: 'countryId', label: 'Country', sortable: true },               
          { key: 'languageId', label: 'Language', sortable: true },         
          { key: 'formattedCreationDate', label: 'Created At', sorter: true },               
          { key: 'Actions', filter: false }
        ]
      },

      formattedPendingCreatives() {
        return this.screeningPendingCreatives        
          .map(creative => {
            return {
              ...creative,
              formattedCreationDate: creative.creationDate ? dateTimeFormat.format(new Date(creative.creationDate)) : '',
              accountName: this.getSafe(() => creative.account.name),
              categoryName: this.getSafe(() => creative.category.name),
              trafficSourceName: this.getSafe(() => creative.trafficSource.name),
              countryId: this.getSafe(() => creative.country.id),
              languageId: this.getSafe(() => creative.language.id),
            }
        })
      }      
    },
  
    async created() {
      try {
        this.isLoading = true
        await this.getScreeningPendingCreatives()
      } finally {
        this.isLoading = false
      }
    },
  
    beforeDestroy() {
      this.clearAutoReloadInterval()
    },
  
    methods: {
      ...mapActions('creative', ['getScreeningPendingCreatives']),
  
      onChangeAutoReload() {
        this.autoReload = !this.autoReload

        if (this.autoReload) {
          autoReloadTimer = setInterval(async () => {
            await this.getScreeningPendingCreatives()
          }, AUTO_RELOAD_INTERVAL)
        } else {
          this.clearAutoReloadInterval()
        }
      },
  
      clearAutoReloadInterval() {
        if (autoReloadTimer) {
          clearInterval(autoReloadTimer)
          autoReloadTimer = null
        }
      },

      copyIdToClipboard(id) {
        this.copyTextToClipboard(id)
        showSuccessMessage('Creative ID copied to clipboard')
      },

      copyAccountIdToClipboard(id) {
        this.copyTextToClipboard(id)
        showSuccessMessage('Account ID copied to clipboard')
      }  
    }
  }
  </script>
  
  <style></style>
  