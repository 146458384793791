<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '20vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>

    <div v-else>
      <CDataTable
        small
        border
        sorter
        column-filter
        :items="accountsFormattedAndFiltered"
        :fields="finalTableFields"
        :items-per-page="10"
        :pagination="{ align: 'center' }"
        :sorter-value="{ column: 'registrationDate', asc: true }"
      >
        <template #email-filter>
          <input
            v-model="searchEmail"
            class="form-control form-control-sm"
          >
        </template>

        <template #registrationDate="{ item }">
          <td>
            {{ item.formattedRegistrationDate }}
          </td>
        </template>

        <template #adminApprovalDate="{item}">
          <td>
            {{ item.formattedAdminApprovalDate }}
          </td>
        </template>

        <template #onboardingStatus="{ item }">
          <td>
            <CBadge :color="getOnboardingStatusBadge(item.onboardingStatus)">
              {{ startCase(item.onboardingStatus) }}
            </CBadge>
          </td>
        </template>

        <template #unmetRequirements="{ item }">
          <td>
            {{ getSafe(() => item.unmetRequirements && item.unmetRequirements.length ? formatUnmetRequirements(item.unmetRequirements) : 'None') }}
          </td>
        </template>

        <template #adminApprovalRequirement="{ item }">
          <td>
            <CBadge
              v-if="item.adminApprovalRequirement === 'Pending'"
              color="warning"
            >
              Pending
            </CBadge>
            <CBadge
              v-else
              color="success"
            >
              Approved
            </CBadge>
          </td>
        </template>

        <template #ringbaAccountId="{item}">
          <td>
            {{ item.ringbaAccountId }}
          </td>
        </template>

        <template #Actions="{ item }">
          <td>
            <CButton
              color="info"
              size="sm"
              :to="`/dashboard/accounts/${type}/${item.id}`"
              target="_blank"
            >
              View Account
            </CButton>
          </td>
        </template>
      </CDataTable>
    </div>
  </CCard>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { startCase } from 'lodash'

import { dateTimeFormat } from '@/utils'
import { COMMON_ACCOUNT_REQUIREMENTS, getUnmetRequirements } from '@/store/modules/accounts.store'

export default {
  name: 'InactiveAccountsList',

  props: {
    type: {
      type: String,
      default: ''
    },

    showFinalApprovalPendingAccounts: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false,
      searchEmail: "",
      fields: [
        { key: "onboardingStatus", label: "Onboarding Status" },
        { key: "name", label: "Account Name" },
        { key: "registrationDate", label: "Registered On" },
        { key: 'ringbaAccountId', label: 'Ringba Account Id', sorter: false },
        { key: "callTechnology", label: "Technology" },
        { key: "country", label: "Country" },
        { key: "representativeName", label: "Representative" },
        { key: "email", label: "Email" },
        { key: "adminApprovalRequirement", label: "Final Approval", sorter: true },
        { key: "Actions", filter: false, hide: this.showFinalApprovalPendingAccounts }
      ],
      COMMON_ACCOUNT_REQUIREMENTS
    }
  },
  computed: {
    ...mapState("accounts", [
      'pendingSellerAccounts',
      'pendingBuyerAccounts'
    ]),

    ...mapGetters("accounts", [
      'pendingSellerAccountsWithNoAdminApproval',
      'pendingBuyerAccountsWithNoAdminApproval'
    ]),

    finalTableFields() {
      let fields = [...this.fields]

      if (!this.showFinalApprovalPendingAccounts) {
        fields.splice(-2, 0, { key: "unmetRequirements", label: "Pending Requirements", sorter: false })
        fields.splice(-1, 0, { key: 'adminApprovalDate', label: 'Final Approval Date', sorter: true })
      }
      
      return fields
    },

    accountsList() {
      switch (this.type) {
        case 'buyer':
          return this.showFinalApprovalPendingAccounts ? this.pendingBuyerAccountsWithNoAdminApproval : this.pendingBuyerAccounts

        case 'seller':
          return this.showFinalApprovalPendingAccounts ? this.pendingSellerAccountsWithNoAdminApproval : this.pendingSellerAccounts

        default:
          return []
      }
    },

    accountsFormattedAndFiltered() {
      const accounts = [...this.accountsList]

      return accounts.filter(account => {
        if (!this.searchEmail) {
          return true
        }
        return account.representative.email.toLowerCase().includes(this.searchEmail.toLowerCase().trim())
      }).map(acc => {
        return {
          ...acc,
          email: acc.representative.email,
          country: acc.companyAddress.countryCode,
          representativeName: `${acc.representative.firstName} ${acc.representative.lastName}`, 
          formattedRegistrationDate: dateTimeFormat.format(new Date(acc.registrationDate)),
          formattedAdminApprovalDate: !acc.adminApprovalDate || new Date(acc.adminApprovalDate).getFullYear() === 1 ? '' : dateTimeFormat.format(new Date(acc.adminApprovalDate)),
          unmetRequirements: getUnmetRequirements(acc.requirements),
          adminApprovalRequirement: getUnmetRequirements(acc.requirements).includes(COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT) ? 'Pending' : 'Approved',
        }
      })
    }
  },
  async created() {
    try {
      this.isLoading = true
      if (this.type === 'buyer') {
        await this.getPendingBuyerAccounts()
      }
      else {
        await this.getPendingSellerAccounts()
      }
    }
    catch {
      //
    }
    finally {
      this.isLoading = false
    }
  },
  methods: {
    startCase,
    ...mapActions("accounts", [
      "getPendingSellerAccounts",
      "getPendingBuyerAccounts"
    ]),
    getOnboardingStatusBadge(status) {
      switch (status) {
        case 'Completed':
          return 'success'

        case "Pending":
        case "BankInfoSubmitted":
        case "PendingDocumentSigning":
        default:
          return "warning"
      }
    },

    formatUnmetRequirements(unmetRequirements) {
      return unmetRequirements
        .filter(r => r !== COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT)
        .map(r => startCase(r))
        .join(', ')
    }
  },
}
</script>
<style lang="">
  
</style>