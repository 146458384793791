<template>
  <div class="d-flex">
    <div
      class="traffic-status px-3 py-1"
      :class="isBiddingStoppped ? 'text-danger' : 'text-success'"
    >
      <span
        :class="isBiddingStoppped ? 'bg-danger' : 'bg-success'"
        class="circle mr-2"
      />
      {{ isBiddingStoppped ? 'Bidding Stopped' : 'Bidding Enabled' }}
    </div>

    <CButton
      :color="isBiddingStoppped ? 'success' : 'danger'"
      variant="outline"
      size="sm"
      @click="triggerStatusChange"
    >
      {{ isBiddingStoppped ? 'Resume Bidding' : 'Stop Bidding' }}
    </CButton>

    <CModal
      title="Please login again to verify it's you"
      color="primary"
      :show.sync="showLoginModal"
    >
      <LoginInternal
        v-if="showLoginModal"
        @success="onLoginSuccess"
        @error="onLoginFailed"
      />

      <template #footer-wrapper>
        <div />
      </template>
    </CModal>

    <CModal
      :title="newBiddingStoppedStatus ? 'Confirm stop bidding' : 'Confirm resume bidding'"
      :color="newBiddingStoppedStatus ? 'danger' : 'success'"
      :show.sync="showConfirmationModal"
    >
      Are you sure you want to {{ newBiddingStoppedStatus ? 'stop' : 'resume' }} bidding on the platform?

      <CTextarea
        v-model="note"
        class="mt-2"
        name="Note"
        label="Note"
        :is-valid="isNoteValid"
        invalid-feedback="Note is mandatory"
        small
        required
        rows="3"
        :debounce="500"
      />

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          :disabled="!isNoteValid"
          @click="onConfirmSubmit"
        >
          {{ newBiddingStoppedStatus ? 'Stop' : 'Resume' }}
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showConfirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import LoginInternal from '@/views/Login/LoginInternal.vue'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'

export default {
  name: 'SystemStatus',

  components: {
    LoginInternal,
  },

  data() {
    return {
      showLoginModal: false,
      isLoading: false,
      newBiddingStoppedStatus: false,
      showConfirmationModal: false,
      note: ''
    }
  },

  computed: {
    ...mapState('globalSettings', ['isBiddingStoppped']),

    ...mapGetters('auth', [
      'isUserLoggedInWithinLastXMinutes'
    ]),

    isUserLoggedInWithinLast5Minutes() {
      return this.isUserLoggedInWithinLastXMinutes(5)
    },

    isNoteValid() {
      return !!this.note.length
    },
  },

  watch: {
    showLoginModal(newValue) {
      if (newValue) {
        this.oldTitle = document.title
      } else {
        document.title = this.oldTitle
      }
    }
  },

  async created () {
    await this.getBiddingStoppedStatus()

    this.statusInterval = setInterval(async () => {
      await this.getBiddingStoppedStatus()
    }, 10000)
  },

  destroyed () {
    clearInterval(this.statusInterval)
  },

  methods: {
    ...mapActions('globalSettings', ['getBiddingStoppedStatus', 'setBiddingStoppedStatus']),

    ...mapActions('auth', [
      'logout'
    ]),

    triggerStatusChange() {
      this.newBiddingStoppedStatus = !this.isBiddingStoppped

      if (!this.isUserLoggedInWithinLast5Minutes) {
        this.showLoginModal = true
        return
      }

      this.showConfirmationModal = true
    },

    onLoginSuccess() {
      this.showLoginModal = false

      this.showConfirmationModal = true
    },

    onLoginFailed() {
      this.onLogout()
    },

    async onConfirmSubmit() {
      try {
        this.isLoading = true

        await this.setBiddingStoppedStatus({ note: this.note, status: this.newBiddingStoppedStatus })

        this.showConfirmationModal = false

        showSuccessMessage(this.newBiddingStoppedStatus ? 'Bidding has been stopped' : 'Bidding has been resumed')

        this.note = ''
      } catch(error) {
        if (error.response.status === 403) {
          this.showLoginModal = true 
          this.showConfirmationModal = false
        }

        showErrorMessage(error.response.data.title)
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.traffic-status {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.5;
}
</style>