import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const userServiceBaseUrl = new URL('/api/v1/users/account-users', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href
const userServiceHttpClient = createHttpClient(userServiceBaseUrl)

const END_POINT = 'user-accounts'
const PENDING_ACCOUNTS_END_POINT = 'pending-user-accounts'

function handleBlobResponse(response) {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  let headerLine = response.headers['content-disposition']
  let startFileNameIndex = headerLine.indexOf('=') + 1
  let endFileNameIndex = headerLine.length
  let filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
  link.setAttribute('download', filename) //or any other extension
  document.body.appendChild(link)
  link.click()
}

const AccountsAPI = {
  getBuyerAccounts(data = {}) {
    const url = `${END_POINT}/buyer`

    return httpClient.post(url, data)
  },

  searchBuyerAccounts(searchString) {
    const url = `${END_POINT}/buyer/search?query=${encodeURIComponent(searchString)}`
    return httpClient.get(url)
  },

  getBuyerAccountDetails(id) {
    const url = `${END_POINT}/buyer/${id}`

    return httpClient.get(url)
  },

  getBuyerAccountRequirements(id) {
    const url = `${END_POINT}/buyer/${id}/requirements`

    return httpClient.get(url)
  },

  getSellerAccountRequirements(id) {
    const url = `${END_POINT}/seller/${id}/requirements`

    return httpClient.get(url)
  },

  getAccountBalance(id) {
    const url = `${END_POINT}/billing/${id}/balance`

    return httpClient.get(url)
  },

  addFundsToBuyerAccount(id, data) {
    const url = `${END_POINT}/billing/buyer/${id}/add-funds`

    return httpClient.post(url, data)
  },

  getAdvancePayRequests({pendingOnly}) {
    const url = `${END_POINT}/advance-pay/?pendingOnly=${pendingOnly}`
    const result = httpClient.get(url)
    return result
  },

  finalizeAdvancePayRequest(advancePayRequest) {
    const url = `${END_POINT}/advance-pay/complete`
    return httpClient.post(url, advancePayRequest)
  },

  addFundsToSellerAccount(id, data) {
    const url = `${END_POINT}/billing/seller/${id}/add-funds`

    return httpClient.post(url, data)
  },

  getSellerAccounts(data = {}) {
    const url = `${END_POINT}/seller`

    return httpClient.post(url, data)
  },

  async getApprovedSellerAccounts(categoryId) {
    let url = `${END_POINT}/seller/approved`

    if (categoryId) {
      url += `?categoryId=${categoryId}`
    }

    return await httpClient.get(url)
  },

  async getApprovedBuyerAccounts(categoryId) {
    let url = `${END_POINT}/buyer/approved`

    if (categoryId) {
      url += `?categoryId=${categoryId}`
    }
    
    return await httpClient.get(url)
  },

  searchSellerAccounts(searchString) {
    const url = `${END_POINT}/seller/search?query=${encodeURIComponent(searchString)}`
    return httpClient.get(url)
  },

  getSellerAccountDetails(id) {
    const url = `${END_POINT}/seller/${id}`

    return httpClient.get(url)
  },

  getSellerAccountBankNotRedacted(id) {
    const url = `${END_POINT}/${id}/ach`

    return httpClient.get(url)
  },

  updateBuyerAccountBillingConfig({ accountId, billingConfig }) {
    const url = `${END_POINT}/buyer/${accountId}/update-billing-configuration`

    return httpClient.post(url, billingConfig)
  },

  updateSellerAccountBillingConfig({ accountId, billingConfig }) {
    const url = `${END_POINT}/seller/${accountId}/update-billing-configuration`

    return httpClient.post(url, billingConfig)
  },

  updateBuyerAccountTrafficConfig({ accountId, trafficConfig }) {
    const url = `${END_POINT}/buyer/${accountId}/traffic-configuration`

    return httpClient.put(url, trafficConfig)
  },

  updateSellerAccountTrafficConfig({ accountId, trafficConfig }) {
    const url = `${END_POINT}/seller/${accountId}/traffic-configuration`

    return httpClient.put(url, trafficConfig)
  },

  getSellerAccountSignedDocumentBundleFiles(accountId, bundleId) {
    const url = `${END_POINT}/seller/${accountId}/bundle/${bundleId}`

    return httpClient.get(url,  { responseType: 'blob', timeout: 0 }).then(handleBlobResponse)
  },

  getBuyerAccountSignedDocumentBundleFiles(accountId, bundleId) {
    const url = `${END_POINT}/buyer/${accountId}/bundle/${bundleId}`

    return httpClient.get(url,  { responseType: 'blob', timeout: 0 }).then(handleBlobResponse)
  },

  fetchAllowedCategoriesAndSources(accountId) {
    return Promise.resolve({
      data: {
        accountId,
        allowedCategories: [
          {
            id: 'CAT3fc4697f40e34a8883e90384913b498f',
            name: 'Auto Sales'
          },
          {
            id: 'CAT3fc4697f40e34a8883e90384913b498f',
            name: 'Auto Insurance'
          }
        ],
        allowedTrafficSources: [
          {
            id: 'TS1',
            name: 'Direct Mail Inbound'
          },
          {
            id: 'TS2',
            name: 'Direct Mail Outbound'
          }
        ]
      }
    })
  },

  updateBuyerAccountDetails({ accountId, updateObject }) {
    const url = `${END_POINT}/buyer/${accountId}`

    // Don't allow this to be updated from the portal
    delete updateObject.testOnly

    return httpClient.patch(url, updateObject)
  },

  updateSellerAccountDetails({ accountId, updateObject }) {
    const url = `${END_POINT}/seller/${accountId}`

    // Don't allow this to be updated from the portal
    delete updateObject.testOnly

    return httpClient.patch(url, updateObject)
  },

  updateSellerBidPreferences({accountId, bidPreferences}) {
    const url = `${END_POINT}/seller/${accountId}/bid-preferences`
    return httpClient.put(url, bidPreferences)
  },

  /**
   * Update buyer account budget cap
   * @param {string} accountId 
   * @param {{ dailyBudgetInUSD: number, adminDailyBudgetCapInUSD: number }} budget 
   * @returns void
   */
  async updateBuyerAccountBudgetCap(accountId, budget) {
    return await httpClient.put(`${END_POINT}/buyer/${accountId}/budget`, budget)
  },

  async updateBuyerRtbSettings(accountId, rtbSettings) {
    return await httpClient.put(`${END_POINT}/buyer/${accountId}/rtbsettings`, rtbSettings)
  },

  disableAccount(accountID, type, data) {
    const url = `${END_POINT}/${type}/${accountID}/disable`

    return httpClient.post(url, data)
  },

  enableAccount(accountID, type, data) {
    const url = `${END_POINT}/${type}/${accountID}/enable`

    return httpClient.post(url, data)
  },

  getPendingBuyerAccounts() {
    const url = `${PENDING_ACCOUNTS_END_POINT}/buyer`

    return httpClient.get(url)
  },

  getPendingSellerAccounts() {
    const url = `${PENDING_ACCOUNTS_END_POINT}/seller`

    return httpClient.get(url)
  },

  async getAccountUsers(accountId) {
    const url = `/${accountId}`
    return await userServiceHttpClient.get(url)
  },

  /**
   * Create user and add to account
   * @param {string} accountId Account Id
   * @param {Object} user 
   * @returns 
   */
  async createAccountUser(accountId, user) {
    const url = `/${accountId}`
    return await userServiceHttpClient.post(url, user)
  },

  async markAccountAdminApprovalRequirement(accountId, accountType, approve) {
    const url = `${END_POINT}/${accountType}/${accountId}/requirements/admin-approval`

    return await httpClient.post(url, {
      satisfied: approve
    })
  },

  removeAccountUser(accountId, userId) {
    const url = `/${accountId}/admin/${userId}`

    return userServiceHttpClient.delete(url)
  },

  /**
   * Get buyer auto pause parameters
   * @param {string} accountId 
   * @returns 
   */
  async getBuyerAutoPauseParameters(accountId) {
    const url = `${END_POINT}/buyer/${accountId}/auto-pause-settings`
    return await httpClient.get(url)
  },

  /**
   * 
   * @param {string} accountId 
   * @param {{ maxUnansweredCallsWithinHours: Number, maxUnansweredCallsWithinDay: Number, intervalOfHoursForUnansweredCalls: Number }} autoPauseDetails 
   * @returns 
   */
  async updateBuyerAutoPauseParameters(accountId, autoPauseDetails) {
    const url = `${END_POINT}/buyer/${accountId}/auto-pause-settings`
    return await httpClient.put(url, autoPauseDetails)
  },
}

export default AccountsAPI
