export default {
  methods: {
    getStatusBadge(campaign) {
      if (campaign.isPaused && campaign.status?.toLowerCase() !== 'deleted') {
        return 'warning'
      }

      switch (campaign.status) {
      case 'Active':
        return 'success'

      case 'Rejected':
      case 'Deleted':
        return 'danger'

      case 'InReview':
      default:
        return 'warning'
      }
    },

    getStatus(campaign) {
      if (!campaign) {
        return ''
      }

      if (campaign.status?.toLowerCase() === 'deleted') {
        return campaign.status
      }

      if (campaign.isPaused) {
        return 'Paused'
      }

      if (campaign.status?.toLowerCase() === 'inreview') {
        return 'Review Pending'
      }

      return campaign.status
    },

    canCampaignBeResumed(campaign) {
      return campaign?.status?.toLowerCase() === 'paused' || campaign?.isPaused
    },

    canCampaignBeArchived(campaign) {
      return campaign?.status?.toLowerCase() !== 'deleted'
    },
  }
}