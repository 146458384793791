<template lang="">
  <CCard>
    <CCardHeader>
      Auto Pause Parameters for Buyer Campaigns
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow class="mb-3">
          <CCol
            sm="3"
            class="pr-3"
          >
            <label class="col-form-label">
              Override Default Parameters
            </label>
          </CCol>

          <CCol
            sm="9"
          >
            <CSwitch
              shape="pill"
              color="primary"
              horizontal
              add-label-classes=""
              add-input-classes="w-25"
              size="lg"
              :checked.sync="overrideDefaultParameters"
            />
          </CCol>
        </CRow>

        <CInput
          v-if="overrideDefaultParameters"
          v-model="$v.autoPauseParameters.intervalOfHoursForUnansweredCalls.$model"
          type="number"
          label="Duration for auto pause (in hours)"
          name="intervalOfHoursForUnansweredCalls"
          placeholder="Hours"
          :is-valid="checkIfValid(['autoPauseParameters', 'intervalOfHoursForUnansweredCalls'])"
          horizontal
          invalid-feedback="Invalid duration to consider buyer campaign for auto pause"
        >
          <template v-slot:description>
            <div
              v-if="autoPauseParameters.intervalOfHoursForUnansweredCalls == defaultBuyerAutoPauseParameters.intervalOfHoursForUnansweredCalls"
              class="text-muted mt-1"
            >
              <i>Default config for all buyers</i>
            </div>
          </template>
        </CInput>

        <CInput
          v-if="overrideDefaultParameters"
          v-model="$v.autoPauseParameters.maxUnansweredCallsWithinHours.$model"
          type="number"
          label="No. of calls within above duration"
          name="maxUnansweredCallsWithinHours"
          placeholder="Unanswered Call count"
          :is-valid="checkIfValid(['autoPauseParameters', 'maxUnansweredCallsWithinHours'])"
          horizontal
          invalid-feedback="Invalid calls count"
        >
          <template v-slot:description>
            <div
              v-if="autoPauseParameters.maxUnansweredCallsWithinHours == defaultBuyerAutoPauseParameters.maxUnansweredCallsWithinHours"
              class="text-muted mt-1"
            >
              <i>Default config for all buyers</i>
            </div>
          </template>
        </CInput>

        <CInput
          v-if="overrideDefaultParameters"
          v-model="$v.autoPauseParameters.maxUnansweredCallsWithinDay.$model"
          type="number"
          label="No. of calls within a day"
          name="maxUnansweredCallsWithinDay"
          placeholder="Unanswered Call count"
          :is-valid="checkIfValid(['autoPauseParameters', 'maxUnansweredCallsWithinDay'])"
          horizontal
          invalid-feedback="Invalid calls count"
        >
          <template #description>
            <div
              class="text-muted mt-1"
            >
              <i
                v-if="autoPauseParameters.maxUnansweredCallsWithinDay == defaultBuyerAutoPauseParameters.maxUnansweredCallsWithinDay"
              >Default config for all buyers</i>
            </div>
          </template>
        </CInput>

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <CButton
              type="submit"
              size="sm"
              color="primary"
              @click="askConfirmation"
            >
              Update
            </CButton>
          </CCol>
        </div>
      </CForm>
    </CCardBody>

    <CModal
      title="Confirm change buyer auto pause parameters"
      color="success"
      :show.sync="showConfirmationModal"
    >
      Are you sure you want to change buyer's auto pause parameters?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="confirmUpdateBuyerAutoPauseParameters"
        >
          Update
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showConfirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { minValue, requiredIf, } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'
import isEmpty from 'lodash.isempty'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showSuccessMessage } from '@/notification-utils'

const defaultFormAutoPauseParameters = {
  intervalOfHoursForUnansweredCalls: null,
  maxUnansweredCallsWithinDay: null,
  maxUnansweredCallsWithinHours: null,
}

export default {
  name: 'BuyerAutoPauseParameters',

  mixins: [formGenericMixin],

  props: {
    accountId: {
      type: String,
      required: true
    },

    buyerAutoPauseParameters: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  validations() {
    return {
      autoPauseParameters: {
        intervalOfHoursForUnansweredCalls: {
          requiredIf: requiredIf(() => {
            return this.overrideDefaultParameters
          }),
          minValue: minValue(1)
        },

        maxUnansweredCallsWithinDay: {
          requiredIf: requiredIf(() => {
            return this.overrideDefaultParameters
          }),
          minValue: minValue(1)
        },

        maxUnansweredCallsWithinHours: {
          requiredIf: requiredIf(() => {
            return this.overrideDefaultParameters
          }),
          minValue: minValue(1)
        }
      }
    }
  },

  data() {
    return {
      isLoading: false,

      showConfirmationModal: false,

      setOverrideDefaultParameters: false,

      autoPauseParameters: {
        intervalOfHoursForUnansweredCalls: null,
        maxUnansweredCallsWithinDay: null,
        maxUnansweredCallsWithinHours: null,
      }
    }
  },

  computed: {
    ...mapState('accounts', [
      'defaultBuyerAutoPauseParameters'
    ]),

    isDefaultAutoPauseParametersConfig() {
      return this.autoPauseParameters == null 
          || (this.autoPauseParameters.maxUnansweredCallsWithinDay === this.defaultBuyerAutoPauseParameters.maxUnansweredCallsWithinDay
            && this.autoPauseParameters.maxUnansweredCallsWithinHours === this.defaultBuyerAutoPauseParameters.maxUnansweredCallsWithinHours
            && this.autoPauseParameters.intervalOfHoursForUnansweredCalls === this.defaultBuyerAutoPauseParameters.intervalOfHoursForUnansweredCalls)
          || (this.autoPauseParameters.maxUnansweredCallsWithinDay === defaultFormAutoPauseParameters.maxUnansweredCallsWithinDay
            && this.autoPauseParameters.maxUnansweredCallsWithinHours === defaultFormAutoPauseParameters.maxUnansweredCallsWithinHours
            && this.autoPauseParameters.intervalOfHoursForUnansweredCalls === defaultFormAutoPauseParameters.intervalOfHoursForUnansweredCalls)
    },

    overrideDefaultParameters: {
      get() {
        return this.setOverrideDefaultParameters || !this.isDefaultAutoPauseParametersConfig
      },

      set(value) {
        this.setOverrideDefaultParameters = value

        if (value) {
          this.autoPauseParameters = {...this.defaultBuyerAutoPauseParameters}
        } else {
          this.autoPauseParameters = {...defaultFormAutoPauseParameters}
        }
      }
    }
  },

  watch: {
    buyerAutoPauseParameters: {
      immediate: true,
      handler(newValue) {
        this.setOverrideDefaultParameters = false

        this.autoPauseParameters = !isEmpty(newValue) ? {
          ...newValue
        } : {
          ...defaultFormAutoPauseParameters
        }
      }
    }
  },

  methods: {
    ...mapActions('accounts', [
      'updateBuyerAutoPauseParameters', 
    ]),

    askConfirmation() {
      this.submitted = true

      if (this.isValid) {
        this.showConfirmationModal = true
      }
    },

    async confirmUpdateBuyerAutoPauseParameters() {
      this.isLoading = true

      try {
        await this.updateBuyerAutoPauseParameters({
          accountId: this.accountId,
          autoPauseParameters: this.autoPauseParameters
        })

        this.showConfirmationModal = false

        showSuccessMessage('Successfully updated buyer auto pause parameters')

        this.resetFormDirtyState()
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="">
  
</style>