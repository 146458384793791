<template>
  <CCard>
    <CCardHeader
      data-cy="header"
    >
      <slot name="header" />
    </CCardHeader>


    <CCardBody>
      <slot name="form" />

      <div
        v-if="isLoadingList"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
          grow
        />
      </div>
      <div v-else>
        <CDataTable
          data-cy="table-users"
          small
          border
          sorter
          column-filter
          :sorter-value="{ column: 'name', asc: true }"
          :items="usersCopy"
          :fields="tableFields"
          :items-per-page="10"
          :pagination="{align: 'center'}"
        >
          <template #status="{item}">
            <td>
              <CBadge :color="getStatusBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>
          </template>

          <template #Actions="{item}">
            <td>
              <CButton
                v-if="!hideDisable && loggedInUserID !== item.id && item.status === ACTIVE_STATUS"
                color="warning"
                size="sm"
                class="mt-2"
                @click="showDisableUserModal(item)"
              >
                Disable User
              </CButton>
              <CButton
                v-if="!hideDisable && loggedInUserID !== item.id && item.status === DISABLED_STATUS"
                color="success"
                size="sm"
                class="mt-2"
                @click="showEnableUserModal(item)"
              >
                Enable User
              </CButton>
              <CButton
                v-if="loggedInUserID !== item.id && accountId"
                color="danger"
                size="sm"
                class="mt-2"               
                @click="showRemoveUserFromAccountModal(item)"
              >
                Remove User
              </CButton>

              <CButton
                v-if="loggedInUserID !== item.id"
                color="secondary"
                size="sm"
                class="mt-2 ml-2"               
                @click="showResetMfaModal(item)"
              >
                Reset MFA
              </CButton>
            </td>
          </template>
        </CDataTable>
      </div>
    </CCardBody>

    <CModal
      title="Confirm reset MFA"
      color="danger"
      :show.sync="resetMfaModal"
    >
      Are you sure you want to reset this user's MFA?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="confirmResetMfa(selectedUser)"
        >
          Reset
        </ButtonLoading>

        <CButton
          color="danger"
          @click="resetMfaModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm remove from account"
      color="danger"
      :show.sync="removeUserFromAccountModal"
    >
      Are you sure you want to remove this user from account?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="confirmRemoveUserFromAccount(selectedUser)"
        >
          Remove
        </ButtonLoading>

        <CButton
          color="danger"
          @click="removeUserFromAccountModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm disable"
      color="warning"
      data-cy="disable-user-modal"
      :show.sync="disableUserModal"
    >
      Are you sure you want to disable this user?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="confirmDisableUser(selectedUser)"
        >
          Disable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="disableUserModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm enable"
      color="success"
      data-cy="enable-user-modal"
      :show.sync="enableUserModal"
    >
      Are you sure you want to enable this user?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="confirmEnableUser(selectedUser)"
        >
          Enable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="enableUserModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { showSuccessMessage } from '@/notification-utils'
import { ACTIVE_STATUS, DISABLED_STATUS } from '@/store/modules/users.store'
import { dateTimeFormat } from '@/utils'
import { cloneDeep } from 'lodash'

export default {
  name: 'UsersList',

  props: {
    users: {
      type: Array,
      default() {
        return []
      }
    },

    accountId: {
      type: String,
      default: ''
    },

    isLoadingList: {
      type: Boolean,
      default: false
    },

    hideDisable: {
      type: Boolean,
      default: false
    },

    hideDelete: {
      type: Boolean,
      default: false
    },

    hideStatusColumn: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ACTIVE_STATUS,
      DISABLED_STATUS,
      isLoading: false,
      enableUserModal: false,
      disableUserModal: false,
      removeUserFromAccountModal: false,
      resetMfaModal: false,
      enableAutoreload: true,
      usersCopy: [],
      fields: [
        { key: 'status', sortable: true },
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'creationDate', label: 'Creation Date', sortable: true },
        { key: 'Actions', filter: false }
      ],

      selectedUser: null
    }
  },

  computed: {
    ...mapGetters('auth', [
      'loggedInUserID'
    ]),

    tableFields() {
      let fields = [...this.fields]

      if (this.hideStatusColumn) {
        fields = fields.filter(f => f.key !== 'status')
      }

      return fields
    },

    usersFormatted() {
      return this.users.map(user => {
        return {
          ...user,
          creationDate: dateTimeFormat.format(new Date(user.creationDate))
        }
      })
    }
  },

  watch: {
    users: {
      immediate: true,

      handler(newValue) {
      this.usersCopy = cloneDeep(newValue).map(a => {        
        a.creationDate = dateTimeFormat.format(new Date(a.creationDate))
        return a
      })
    }
    }
  },


  methods: {
    ...mapActions('users', [
      'disableUser',
      'enableUser'
    ]),

    ...mapActions('accounts', ['removeAccountUser']),
    ...mapActions('auth', ['resetMfa']),

    async confirmRemoveUserFromAccount(user) {
      try {
        this.isLoading = true
        await this.removeAccountUser({accountId: this.accountId, userId: user.id})

        showSuccessMessage('Successfully removed user from account')

        this.removeUserFromAccountModal = false
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    async confirmResetMfa(user) {
      try {
        this.isLoading = true
        await this.resetMfa(user.email)

        showSuccessMessage("Successfully reset user's MFA")
        this.resetMfaModal = false
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },


    async confirmDisableUser(user) {
      try {
        this.isLoading = true
        await this.disableUser(user.id)

        showSuccessMessage('Successfully disabled user')
        this.disableUserModal = false
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    async confirmEnableUser(user) {
      try {
        this.isLoading = true
        await this.enableUser(user.id)

        showSuccessMessage('Successfully enabled user')
        this.enableUserModal = false
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    showRemoveUserFromAccountModal(user) {
      this.selectedUser = user
      this.removeUserFromAccountModal = true
    },

    showResetMfaModal(user) {
      this.selectedUser = user
      this.resetMfaModal = true
    },

    showDisableUserModal(user) {
      this.selectedUser = user
      this.disableUserModal = true
    },

    showEnableUserModal(user) {
      this.selectedUser = user
      this.enableUserModal = true
    },

    getStatusBadge(status) {
      switch (status) {
        case 'Active':
          return 'success'

        case 'Disabled':
        default:
          return 'warning'
      }
    }
  },
}
</script>

<style>

</style>