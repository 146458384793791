<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '20vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>

    <div v-else>
      <CDataTable
        data-cy="table-registration-requests"
        small
        border
        sorter
        column-filter
        :items="requestsFormatted"
        :fields="fields"
        :items-per-page="10"
        :pagination="{align: 'center'}"
        :sorter-value="{ column: 'requestedOn', asc: false }"
      >
        <template #email-filter>
          <input
            v-model="searchEmail"
            class="form-control form-control-sm"
          >
        </template>
    
        <template #status-filter>
          <select
            v-model="status"
            class="form-control form-control-sm"
            @change="onStatusChange"
          >
            <option
              value="All"
              selected="selected"
            />
            <option value="Pending">
              Pending
            </option>
            <option value="Waitlisted">
              Waitlisted
            </option>
          </select>
        </template>

        <template #email="{item}">
          <td>{{ item.representative.email }}</td>
        </template>
        
        <template #companyName="{item}">
          <td>
            {{ item.companyName }}

            <CBadge
              v-if="item.testOnly"
              color="danger"
              class="ml-1"
            >
              Test
            </CBadge>
          </td>
        </template>

        <template #requestedOn="{item}">
          <td class="text-nowrap">
            {{ item.formattedRequestedOn }}
          </td>
        </template>

        <template #lastUpdatedAt="{item}">
          <td>
            {{ item.formattedLastUpdatedAt }}
          </td>
        </template>

        <template #technology="{item}">
          <td class="text-nowrap">
            {{ getSafe(() => item.callTechnology) }}
          </td>
        </template>

        <template #country="{item}">
          <td class="text-nowrap">
            {{ getSafe(() => item.companyAddress.countryCode) }}
          </td>
        </template>

        <template #ipCountry="{item}">
          <td class="text-nowrap">
            {{ getSafe(() => item.geoIpInfo.ipCountry) }}
          </td>
        </template>

        <template #representative="{item}">
          <td class="text-nowrap">
            {{ getSafe(() => `${item.representative.firstName} ${item.representative.lastName}`) }}
          </td>
        </template>

        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">
              {{ item.status }}
            </CBadge>
          </td>
        </template>

        <template #Actions="{item}">
          <td>
            <CButton
              color="info"
              size="sm"
              :to="`/dashboard/registration-requests/${type}/${item.id}`"
            >
              View Application
            </CButton>
          </td>
        </template>
      </CDataTable>
    </div>
  </CCard>
</template>

<script>
import { dateTimeFormat } from '@/utils'
import statusMixin from '@/mixins/status-mixin'

export default {
  name: 'RegistrationRequestsList',

  mixins: [statusMixin],

  props: {
    registrationRequests: {
      type: Array,
      default() {
        return []
      }
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: ''
    }
  },
  
  data() {
    return {
      searchEmail: '',
      status:'',
      fields: [
        { key: 'status', label: 'Status' },
        { key: 'requestedOn', label: 'Applied', sorter: true },
        { key: 'lastUpdatedAt', label: 'Last Edited At' },
        { key: 'companyName', label: 'Company' },
        { key: 'technology', label: 'Technology', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'ipCountry', label: 'IP Country', sortable: true },
        { key: 'representative', label: 'Representative', sortable: true },
        { key: 'Actions', filter: false }
      ]
    }
  },
 
  computed: {
    requestsFormatted() {
      return this.registrationRequests.filter(request => {
        if (!this.searchEmail) {
          return true
        }

        return request.representative.email.toLowerCase().includes(this.searchEmail.toLowerCase().trim())
      }).map(request => {
        return {
          ...request,
          formattedRequestedOn: dateTimeFormat.format(new Date(request.requestedOn)),
          formattedLastUpdatedAt: request.lastUpdatedAt ? dateTimeFormat.format(new Date(request.lastUpdatedAt)) : '',
        }
      })
    }
  },

  mounted() {
    // update data when mounting the component
    if ("status" in this.$route.query) {
      this.status = this.$route.query.status
    }
  },

  methods: {
    onStatusChange() {
     
      const query = {}
      query.status = this.status
      
      this.$router.push({ query: query })

      this.$emit("status-change", this.status)
      
    },
 }

}
</script>

<style lang="scss" scoped>

</style>