import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/review', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = 'buyer-campaigns'

const CampaignAPI = {
  async updateCampaignDetails({ id, updateObject }) {
    const url = `${END_POINT}/${id}`
    updateObject.id = id
    return httpClient.patch(url, updateObject)
  },

  async archiveCampaign(id, note) {
    const url = `${END_POINT}/${id}/archive`
    return await httpClient.post(url, {
      note
    })
  },

  async restoreCampaign(id, note) {
    const url = `${END_POINT}/${id}/restore`
    return await httpClient.post(url, {
      note
    })
  },

  async getBuyerCampaigns({detailed, accountId, categoryId, status}) {
    let url = `${END_POINT}?detailed=${Boolean(detailed)}`

    if (accountId) {
      url = url + `&accountId=${accountId}`
    }

    if (categoryId) {
      url = url + `&categoryId=${categoryId}`
    }

    if (status) {
      url = url + `&status=${status}`
    }

    return httpClient.get(url)
  },

  async getBuyerCampaign(id) {
    const url = `${END_POINT}/${id}`
    return httpClient.get(url)
  },
  async approveBuyerCampaign({ id, remarks, version }) {
    const url = `${END_POINT}/${id}/approve`
    return httpClient.post(url, { decisionRemarks: remarks, version })
  },
  async rejectBuyerCampaign({ id, remarks, version }) {
    const url = `${END_POINT}/${id}/reject`
    return httpClient.post(url, { decisionRemarks: remarks, version })
  },
  async getPendingApprovalBuyerCampaigns() {
    const url = `${END_POINT}/pending-review?detailed=true`
    return httpClient.get(url)
  },

  async pauseBuyerCampaign({ id, remarks }) {
    const url = `${END_POINT}/${id}/pause`
    return httpClient.put(url, {
      note: remarks
    })
  },

  async resumeBuyerCampaign({ id, remarks }) {
    const url = `${END_POINT}/${id}/resume`
    return httpClient.put(url, {
      note: remarks
    })
  },
}

export default CampaignAPI
