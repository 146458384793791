var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '20vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CDataTable',{attrs:{"small":"","border":"","sorter":"","column-filter":"","items":_vm.accountsFormattedAndFiltered,"fields":_vm.finalTableFields,"items-per-page":10,"pagination":{ align: 'center' },"sorter-value":{ column: 'registrationDate', asc: true }},scopedSlots:_vm._u([{key:"email-filter",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchEmail),expression:"searchEmail"}],staticClass:"form-control form-control-sm",domProps:{"value":(_vm.searchEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchEmail=$event.target.value}}})]},proxy:true},{key:"registrationDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.formattedRegistrationDate)+" ")])]}},{key:"adminApprovalDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.formattedAdminApprovalDate)+" ")])]}},{key:"onboardingStatus",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getOnboardingStatusBadge(item.onboardingStatus)}},[_vm._v(" "+_vm._s(_vm.startCase(item.onboardingStatus))+" ")])],1)]}},{key:"unmetRequirements",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.unmetRequirements && item.unmetRequirements.length ? _vm.formatUnmetRequirements(item.unmetRequirements) : 'None'; }))+" ")])]}},{key:"adminApprovalRequirement",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.adminApprovalRequirement === 'Pending')?_c('CBadge',{attrs:{"color":"warning"}},[_vm._v(" Pending ")]):_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" Approved ")])],1)]}},{key:"ringbaAccountId",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.ringbaAccountId)+" ")])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","size":"sm","to":("/dashboard/accounts/" + _vm.type + "/" + (item.id)),"target":"_blank"}},[_vm._v(" View Account ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }