<template>
  <div>
    <CCard
      v-if="getSafe(() => selectedRow.isCategoryTestOnly)"
      class="test-offer-banner bg-danger p-3 text-white"
    >
      <strong>
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        This is a test-only offer
      </strong>
    </CCard>

    <CCard>
      <div
        v-if="isLoading"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          style="width:4rem;height:4rem;"
          color="info"
          grow
        />
      </div>
      <div v-else>
        <CCardHeader
          component="h5"
          class="d-flex align-items-center"
        >
          Seller Offer: 
          {{ selectedRow.name }}
          <a
            href
            @click.prevent="copyIdToClipboard(selectedRow.id)"
          ><CIcon
            name="cil-copy"
          /></a>
          
          <CBadge
            class="ml-5"
            :color="getStatusBadge(selectedRow.status)"
          >
            {{ selectedRow.status }}
          </CBadge>

          <CButton
            color="info"
            size="sm"
            class="ml-auto"
            @click="copySellerOfferLinkForSellersToClipboard(selectedRow.id)"
          >
            Copy Link for Sellers

            <CIcon
              name="cil-copy"
              size="custom"
              class="ml-1"
            />
          </CButton>
        </CCardHeader>
        <CCardBody>
          <CForm>
            <CInput
              v-model="form.name"
              label="Name"
              horizontal
              placeholder="Seller Offer Name"
            />
            <div
              v-if="!nameSet"
              class="invalid-feedback d-flex mb-3 mt-2"
            >
              <CIcon
                name="cilWarning"
                class="mr-1"
              />
              Please enter meaningful name for the offer
            </div>
            <CTextarea
              v-model="form.description"
              data-cy="input-description"
              label="Description"
              placeholder="Enter description"
              horizontal
              rows="3"
            />

            <CSelect
              label="Category"
              horizontal
              :value.sync="form.categoryId"
              :options="allowedCategoriesFormatted"
              placeholder="Please select"
              invalid-feedback="Please select a category"
              disabled
              readonly
            />

            <CSelect
              label="Language"
              horizontal
              :value.sync="form.languageId"
              :options="languagesList"
              placeholder="Please select"
              invalid-feedback="Please select a language"
              disabled
              readonly
            />

            <CSelect
              label="Visibility"
              horizontal
              :value.sync="form.visibility"
              :options="sellerOfferVisibilityOptions"
              @change="showVisibilityChangeModal"
            />

            <CRow>
              <CCol
                sm="3"
                class="pr-3"
              >
                Affiliate Traffic Allowed?
              </CCol>
              <CCol sm="3">
                <CSwitch
                  label-off="No"
                  label-on="Yes"
                  shape="pill"
                  color="primary"
                  data-cy="toggle-works-with-affiliates"
                  horizontal
                  add-label-classes=""
                  add-input-classes="w-25"
                  size="lg"
                  :checked.sync="form.isAffiliateTrafficAllowed"
                />
              </CCol>
            </CRow>
            <CSelect
              label="Traffic Source Type"
              horizontal
              :value.sync="form.allowedTrafficSourceType"
              :options="trafficSourceTypes"
              placeholder="Please select"
              @input="changeTrafficSourceType"
            />
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3 pr-4">Allowed Traffic Sources</label>
              <div class="col-sm-9">
                <v-multiselect-listbox
                  v-model="allowedTrafficSources"
                  :options="trafficSourcesFiltered"
                  :reduce-display-property="(option) => (option || {}).name"
                  :reduce-value-property="(option) => (option || {}).id"
                  :reduce-group-property="(option) => (option || {}).trafficSourceType"
                  search-input-class="custom-form-input custom-form-input--sm"
                  search-options-placeholder="Search source"
                  selected-options-placeholder="Search selected"
                  selected-no-options-text="No traffic source selected"
                  highlight-diff
                />

                <div
                  v-if="!trafficSourcesSet"
                  class="invalid-feedback d-flex mb-3 mt-2"
                >
                  <CIcon
                    name="cilWarning"
                    class="mr-1"
                  />
                  Please select allowed traffic sources for the offer
                </div>
              </div>
            </div>          
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton
            :disabled="!isFormValid"
            type="submit"
            data-cy="button-submit"
            size="sm"
            color="primary"
            @click.prevent="
              submitUpdateForm({ updateFn: update, entity: 'seller-offers' })
            "
          >
            <CSpinner
              v-if="submitted"
              color="light"
              size="sm"
            />
            <CIcon
              v-else
              name="cil-check-circle"
            /> Submit
          </CButton>
          <CButton
            class="ml-2"
            :disabled="submitted"
            data-cy="button-back"
            type="reset"
            size="sm"
            color="danger"
            @click="backToListView('seller-offers')"
          >
            <CIcon name="cil-arrow-circle-left" /> Back
          </CButton>
        </CCardFooter>
      </div>
    </CCard>

    <BidModifiers
      :parent-object="bidModifiersParentObject"
    />

    <CCard v-if="getSafe(() => selectedRow.visibility === 'Private')">
      <CCardHeader>
        Sellers with Private Access 
        <CIcon 
          v-c-tooltip="'This offer is available exclusively to a selected group of sellers. Other sellers can not see and participate in this offer.'" 
          name="cil-info" 
          size="sm"
          class="ml-2"
        />
      </CCardHeader>
      <CCardBody>
        <SellerAccountsAccessPrivateOffer :seller-offer-id="sellerOfferId" />
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        Approved Sellers
      </CCardHeader>
      <CCardBody>
        <div>
          <CDataTable
            small
            striped
            column-filter
            sorter
            :items="approvedSellersForTable"
            :fields="sellersListFields"
            :items-per-page="10"
            :pagination="{align: 'center'}"
          >
            <template #createdAt="{item}">
              <td>
                {{ getSafe(() => `${item.createdDateFormatted}`) }}
              </td>
            </template>

            <template #enabled="{item}">
              <td>
                <CBadge :color="getSafe(() => item.sellerOfferRegistration.enabled ? 'success' : 'warning')">
                  {{ getSafe(() => item.enabled) }}
                </CBadge>
              </td>
            </template>

            <template #updatedAt="{item}">
              <td>
                {{ getSafe(() => `${formatDate(item.updatedAtFormatted)}`) }}
              </td>
            </template>

            <template #minExpectedBid="{item}">
              <td>
                {{ getSafe(() => formatAmount(item.minExpectedBid)) }}
              </td>
            </template>

            <template #Actions="{item}">
              <td>
                <CButton
                  v-if="item.sellerOfferRegistration.status === 'Approved' && item.sellerOfferRegistration.enabled"
                  color="success"
                  class="ml-2 mt-2"
                  size="sm"
                  @click="showPauseUserModal(item)"
                >
                  Pause
                </CButton>
                <CButton
                  v-if="item.sellerOfferRegistration.status === 'Approved' && !item.sellerOfferRegistration.enabled"
                  color="success"
                  class="ml-2 mt-2"
                  size="sm"
                  @click="showResumeUserModal(item)"
                >
                  Resume
                </CButton>
                <CButton
                  v-if="item.sellerOfferRegistration.status === 'Approved'"
                  class="ml-2 mt-2"
                  color="secondary"
                  size="sm"
                  @click="showDisableUserModal(item)"
                >
                  Remove
                </CButton>
                <CButton
                  color="info"
                  size="sm"
                  class="mt-2 ml-2"
                  :to="`/dashboard/seller-offers/${item.sellerOfferRegistration.sellerOffer.id}/registration/${item.sellerOfferRegistration.id}`"
                >
                  View Details
                </CButton>

                <CButton
                  color="info"
                  class="ml-2 mt-2"
                  size="sm"
                  :to="`/dashboard/accounts/seller/${item.account.id}`"
                >
                  View Account
                </CButton>

                <CButton
                  color="info"
                  size="sm"
                  class="ml-2 mt-2"
                  @click="showBidUrlModal(item.sellerOfferRegistration)"
                >
                  View Bid URL
                </CButton>
              </td>
            </template>
          </CDataTable>

          <!-- Offer bid URL details -->
          <CModal
            title="Bidding URL"
            color="primary"
            :show.sync="showBiddingUrl"
            size="xl"
            @update:show="handleModalClose"
          >
            <SellerOfferBiddingDetails
              v-if="selectedOfferRegistration"
              :offer-registration="selectedOfferRegistration"
            />
          </CModal>
          
          <!-- Remove confirmation modal -->
          <CModal
            title="Confirm remove participation"
            color="warning"
            data-cy="disable-user-modal"
            :show.sync="disableUserModal"
          >
            Are you sure you want to permanently remove this seller from the offer participation?

            <p class="alert alert-warning mt-2">
              <strong>Note:</strong>
              Removal should be used with caution.
              Once removed, the seller cannot send traffic until they reapply and go through the approval process again.
              Consider pausing the traffic instead. It allows to resume the traffic again, without need to reapply.
            </p>

            <template #footer>
              <ButtonLoading
                color="primary"
                :loading="isActionLoading"
                @click="disableUserModal = false, showPauseParticipationModal(selectedUser)"
              >
                Pause Traffic
              </ButtonLoading>
              
              <ButtonLoading
                color="danger"
                :loading="isLoading"
                @click="confirmDisableUser(selectedUser)"
              >
                Yes, Remove
              </ButtonLoading>
            </template>
          </CModal>


          <!-- Pause participation confirmation modal -->
          <CModal
            title="Confirm pause participation"
            color="warning"
            :show.sync="pauseUserModal"
          >
            Are you sure you want to pause this seller's participation in the offer?
            <p class="alert alert-info mt-1">
              <strong>Note:</strong>
              Use this option to quickly stop traffic if there are problems. You can resume the participation once the issue is resolved.
            </p>
            <CForm
              class="mt-3"
              novalidate
              @submit.prevent
            >
              <CInput
                v-model="pauseReason"
                label="Reason"
                horizontal
                placeholder="Please enter reason"
                :is-valid="isPauseFormValid"
                invalid-feedback="Please enter reason for pausing"
              />
            </CForm>

            <template #footer>
              <ButtonLoading
                color="primary"
                :loading="isActionLoading"
                type="submit"
                @click="confirmPauseUser(selectedUser)"
              >
                Pause
              </ButtonLoading>

              <CButton
                color="danger"
                @click="pauseUserModal = false"
              >
                Cancel
              </CButton>
            </template>
          </CModal>

          <!-- Resume participation confirmation modal -->
          <CModal
            title="Confirm resume"
            color="warning"
            :show.sync="resumeUserModal"
          >
            Are you sure you want to resume this seller's participation in the offer?

            <template #footer>
              <ButtonLoading
                color="primary"
                :loading="isActionLoading"
                @click="confirmResumeUser(selectedUser)"
              >
                Resume
              </ButtonLoading>

              <CButton
                color="danger"
                @click="resumeUserModal = false"
              >
                Cancel
              </CButton>
            </template>
          </CModal>

          <!-- Change visibility modal -->
          <CModal
            title="Confirm visibility change"
            color="warning"
            :show.sync="visibilityChangeModal"
          >
            Are you sure you want to change this seller offer's visibility to {{ form.visibility }}?

            <template #footer>
              <ButtonLoading
                color="primary"
                :loading="isActionLoading"
                @click="onVisibilityChange()"
              >
                Confirm
              </ButtonLoading>

              <CButton
                color="danger"
                @click="visibilityChangeModal = false"
              >
                Cancel
              </CButton>
            </template>
          </CModal>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { required  } from 'vuelidate/lib/validators'
import { dateTimeFormat, formatAmount } from '@/utils'
import { mapActions, mapState, mapGetters } from 'vuex'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import formMixins from '@/mixins/form-mixins'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { showSuccessMessage } from '@/notification-utils'
import SellerAccountsAccessPrivateOffer from '@/views/SellerOffers/SellerAccountsAccessPrivateOffer.vue'
import BidModifiers from '@/views/BidModifiers/BidModifiers.vue'
import BidModifierParentType from '@/enums/BidModifierParentType.js'
import SellerOfferBiddingDetails from '@/views/SellerOffers/SellerOfferBiddingDetails.vue'

export default {
  name: 'SellerOfferEdit',

  components: {
    SellerAccountsAccessPrivateOffer,
    BidModifiers,
    SellerOfferBiddingDetails
  },

  mixins: [copyToClipboardMixin, formMixins, formGenericMixin],

  validations() {
    return {
      pauseReason: {
        required
      }
    }
  },

  data() {
    return {
      sellerOfferId: '',
      form: {
        name: '',
        description: '',
        status: null, //{"Disabled", "Active"}
        categoryId: null,
        languageId: null,
        visibility: '',
        isAffiliateTrafficAllowed: false,
        allowedTrafficSourceType: '', //Inbound, Outbound, Transfer
        allowedTrafficSources: [], //{name: '', id: ''}
      },
      trafficSourcesFiltered: [],
      sellersListFields: [
        { key: 'companyName', label: 'Company' },
        { key: 'createdAt', label: 'Applied On' },
        { key: 'updatedAt', label: 'Approved On' },
        { key: 'minExpectedBid', label: 'Min. Expected Bid' },
        { key: 'enabled', label: 'Active' },
        { key: 'pauseReason', label: 'Pause Reason' },
        { key: 'Actions', filter: false, sorter: false }
      ],
      disableUserModal: false,
      selectedUser: null,
      isLoading: false,
      isActionLoading: false,
      pauseUserModal: false,
      resumeUserModal: false,
      visibilityChangeModal: false,
      pauseReason: '',
      selectedOfferRegistration: undefined,
      showBiddingUrl: false
    }
  },

  computed: {
    ...mapState('sellerOffer', ['selectedRow', 'trafficSourceTypes']),
    ...mapState('sellerOfferRegistration', ['approvedSellers']),
    ...mapState('category', ['categories']),
    ...mapState('language', ['languages']),
    ...mapState('trafficSource', ['trafficSourceTypes']),
    ...mapState(['sellerOfferVisibilityOptions']),

    ...mapGetters('trafficSource', {
      trafficSources: 'activeTrafficSources'
    }),

    isPauseFormValid() {
      if (!this.$v.$anyDirty) {
        return null
      }

      return !this.$v.$invalid
    },
    
    allowedCategoriesFormatted() {
      return this.categories.map(category => {
        return {
          value: category.id,
          label: category.name
        }
      })
    },

    nameSet() {
      return (
        this.form.name
      )
    },

    trafficSourcesSet() {
      return (
        this.allowedTrafficSources?.length
      )
    },

    isFormValid() {
      return (
        this.nameSet &&
        this.trafficSourcesSet
      )
    },

    allowedTrafficSources: {
      get() {
        return this.form.allowedTrafficSources?.map(
          (ts) => ts.id
        )
      },

      set(value) {
        this.form.allowedTrafficSources = value.map((id) => {
          return {
            id,
          }
        })
      },
    },

    languagesList() {
      return this.languages.map(language => {
        return {
          value: language.id,
          label: language.name
        }
      })
    },

    bidModifiersParentObject() {
      return {
        parentObjectType: BidModifierParentType.SELLER_OFFER,
        id: this.selectedRow.id,
        alwaysOn: false
      }
    },

    approvedSellersForTable() {
      return this.approvedSellers.map(seller => ({
        ...seller,
        companyName: seller.account.name,
        createdAt: seller.sellerOfferRegistration.createdAt,
        updatedAt: seller.sellerOfferRegistration.updatedAt,
        createdDateFormatted: this.formatDate(seller.sellerOfferRegistration.createdAt),
        updatedAtFormatted: this.formatDate(seller.sellerOfferRegistration.updatedAt),
        enabled: seller.sellerOfferRegistration.enabled ? 'Active' : 'Paused',
        pauseReason: !seller.sellerOfferRegistration.enabled ? seller.sellerOfferRegistration.reason : '',
        minExpectedBid: seller.sellerOfferRegistration.minExpectedBid
      }))
    }
  },

  async created() {
    this.sellerOfferId = this.$route.params.id

    this.isLoading = true

    await this.getAllCategories()
    await this.getAllTrafficSources()
    await this.getAllLanguages()

    await this.findOne(this.sellerOfferId)

    document.title = this.selectedRow.name + ' Offer'

    await this.fetchApprovedSellers(this.sellerOfferId)

    Object.keys(this.form).forEach(key => {
      this.form[key] = this.selectedRow[key]
    })

    this.trafficSourcesFiltered = this.trafficSources
    this.isLoading = false
  },
  methods: {
    ...mapActions('sellerOffer', [
      'findOne', 
      'update', 
      'removeUser', 
      'resumeUser', 
      'pauseUser',
      'changeVisibility'
    ]),

    ...mapActions('sellerOfferRegistration', [
      'fetchApprovedSellers', 
    ]),

    ...mapActions('category', {
      getAllCategories: 'list',
    }),

    ...mapActions('language', {
      getAllLanguages: 'list',
    }),

    ...mapActions('trafficSource', {
      getAllTrafficSources: 'list',
    }),

    formatAmount,

    getStatusBadge(status) {
      switch (status) {
        case 'Active':
          return 'success'

        case 'Disabled':
        default:
          return 'secondary'
      }
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Seller Offer ID copied to clipboard')
    },

    copySellerOfferLinkForSellersToClipboard(id) {
      this.copyTextToClipboard(`${this.CLIENT_SIDE_BASE_URL}/dashboard/campaigns/seller-available-offers?sellerOfferId=${id}`)
      showSuccessMessage('Link to this seller offer has been copied')
    },

    showDisableUserModal(user) {
      this.selectedUser = user
      this.disableUserModal = true
    },

    showPauseUserModal(user) {
      this.selectedUser = user
      this.pauseUserModal = true
    },

    showResumeUserModal(user) {
      this.selectedUser = user
      this.resumeUserModal = true
    },

    showBidUrlModal(offerRegistration) {
      this.showBiddingUrl = true
      this.selectedOfferRegistration = offerRegistration
    },

    showVisibilityChangeModal() {
      this.visibilityChangeModal = true
    },

    async onVisibilityChange(){
      try {
        this.isLoading = true
        
        await this.changeVisibility({id: this.sellerOfferId, visibility: this.form.visibility})
        showSuccessMessage('Successfully changed visibility')
        this.visibilityChangeModal = false
      } catch {
        // already handled in interceptor
      } finally {
        this.isLoading = false
      }
    },

    async confirmDisableUser(row) {
      try {
        this.isLoading = true

        await this.removeUser(row.sellerOfferRegistration.id)
  
        showSuccessMessage('Successfully removed participation')
  
        this.disableUserModal = false
  
        await this.fetchApprovedSellers(row.sellerOfferRegistration.sellerOffer.id)
      } catch {
        // already handled in interceptor
      } finally {
        this.isLoading = false
      }

    },
    
    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },

    changeTrafficSourceType(event) {
      const trafficSourceType = event.target.value.toString()
      const data = this.trafficSources.filter(x => {
        return x.trafficSourceType === trafficSourceType
      })
      this.trafficSourcesFiltered = data
      this.form.allowedTrafficSources = []
    },

    async confirmPauseUser(row) {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      try {
        this.isActionLoading = true

        await this.pauseUser({
          id: row.sellerOfferRegistration.id,
          reason: this.pauseReason
        })

        showSuccessMessage('Successfully paused seller participation')

        this.pauseUserModal = false

        this.pauseReason = ''

        await this.fetchApprovedSellers(this.sellerOfferId)

        this.selectedUser = null
      } catch {
        //
      } finally {
        this.isActionLoading = false
      }
    },

    async confirmResumeUser(row) {
      try {
        this.isActionLoading = true

        await this.resumeUser(row.sellerOfferRegistration.id)

        showSuccessMessage('Successfully resumed seller participation')

        this.resumeUserModal = false

        await this.fetchApprovedSellers(this.sellerOfferId)

        this.selectedUser = null
      } catch {
        //
      } finally {
        this.isActionLoading = false
      }
    },

    handleModalClose(modalOpen) {
      if (!modalOpen) {
        this.selectedOfferRegistration = undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.test-offer-banner {
  position: sticky;
  top: 60px;
  font-size: 1.2rem;
  z-index: 10;
}
</style>