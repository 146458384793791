<template>
  <div>
    <CCard>
      <CCardHeader>
        <div class="d-flex justify-content-between">
          <div>Seller Offer Registration</div>

          <div>
            <CButton
              size="sm"
              color="info"
              class="ml-auto"
              @click="showBiddingUrl = true"
            >
              View Bidding URL
            </CButton>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Status
            </dt>
            <dd class="col-sm-9">
              <CBadge :color="statusVariant">
                {{ requestDetails.status }}
              </CBadge>
            </dd>
            <dt class="col-sm-3 text-right">
              Registration ID
            </dt>
            <dd class="col-sm-9">
              {{ requestDetails.id }}
              <a
                href
                @click.prevent="
                  copyIdToClipboard(requestDetails.id)
                "
              ><CIcon
                name="cil-copy"
              /></a>
            </dd>

            <dt class="col-sm-3 text-right">
              Applied On
            </dt>
            <dd class="col-sm-9">
              {{ getSafe(() => formatDate(requestDetails.createdAt)) }}
            </dd>
          </div>


          <hr>


          <h4>
            Registration Details
          </h4>
          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Will traffic come from 3rd party affiliates?
            </dt>
            <div class="col-sm-3">
              <CSelect
                :value.sync="$v.requestDetailsCopy.thirdPartyTraffic.$model"
                data-cy="input-country"
                label=""
                horizontal
                :options="thirdPartyTrafficOptions"
                placeholder="Please select"
                :is-valid="checkIfValid(['requestDetailsCopy', 'thirdPartyTraffic'])"
                :invalid-feedback="pleaseSelect('third party traffic')"
                @change="handleThirdPartyTrafficChange"
              />
            </div>
          </div>

          <div
            v-if="requestDetailsCopy.thirdPartyTraffic !== 'No'"
            class="form-group form-row"
          >
            <dt class="col-sm-3 text-right">
              Allow Affiliate Traffic
            </dt>
            <div class="col-sm-9 col-xl-5">
              <CSwitch
                label-off="No"
                label-on="Yes"
                shape="pill"
                color="primary"
                horizontal
                add-label-classes=""
                add-input-classes="w-25"
                size="lg"
                :checked.sync="requestDetailsCopy.isAffiliateTrafficApproved"
              />
            </div>
          </div>

          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              URLs for landing pages:
            </dt>
            <div class="col-sm-8 col-xl-5">
              <CTextarea
                v-model="requestDetailsCopy.landingPageUrls"
                placeholder="Enter URLs"
                horizontal
                rows="3"
              />
            </div>
          </div>

          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Specific details regarding promotional methods:
            </dt>
            <div class="col-sm-9 col-xl-5">
              <CTextarea
                v-model="$v.requestDetailsCopy.promotionalMethodsDetails.$model"
                placeholder="Enter details"
                horizontal
                rows="3"
                :is-valid="checkIfValid(['requestDetailsCopy', 'promotionalMethodsDetails'])"
                :invalid-feedback="pleaseSelect('promotional methods details')"
              />
            </div>
          </div>

          <CInput
            v-if="requestDetails.minExpectedBidAmount != null"
            v-model="requestDetails.minExpectedBidAmount"
            readonly
            label="Min. Expected Bid"
            horizontal
            add-label-classes="text-right font-weight-bold"
          />

          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Traffic sources approved
            </dt>
            <div class="col-sm-9 col-xl-5">
              <v-multiselect-listbox
                v-model="approvedTrafficSourcesForm"
                :options="requestDetails.sellerOffer.allowedTrafficSources"
                :reduce-display-property="(option) => option.name"
                :reduce-value-property="(option) => option.id"
                search-input-class="custom-form-input custom-form-input--sm"
                search-options-placeholder="Search source"
                selected-options-placeholder="Search selected"
                selected-no-options-text="No traffic source selected"
                hide-search-inputs
              />

              <div
                v-if="isFormEdited && $v.requestDetailsCopy.approvedTrafficSources.$invalid"
                class="invalid-feedback d-flex mb-3 mt-2"
              >
                Please select traffic source
              </div>
            </div>
          </div>

          <div class="form-group form-row">
            <div class="col-sm-9 offset-sm-3 col-xl-5">
              <ButtonLoading
                color="primary"
                :loading="isOfferChangeLoading"
                @click="submitOfferRegistrationForm"
              >
                Save
              </ButtonLoading>
            </div>
          </div>
        </CForm>

        <hr>
          
        <SellerAccountBasicDetails
          v-if="requestDetails.accountId"
          :account-id="requestDetails.accountId"
        />

        <hr>
          
        <h4>
          Seller Offer Details
        </h4>
        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Name
          </dt>
          <div class="col-sm-9">
            <router-link
              target="_blank"
              title="View Offer"
              :to="{
                name: 'SellerOfferEdit',
                params: { id: getSafe(() => requestDetails.sellerOffer.id) },
                exact: true,
              }"
            >
              {{ getSafe(() => requestDetails.sellerOffer.name) }}
              <CIcon
                name="cil-external-link"
                size="custom"
                class="ml-1"
              />
            </router-link>
            {{ }}
          </div>
        </div>
        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Description
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.sellerOffer.description) }}
          </div>
        </div>
        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Category
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.categoryName) }}
          </div>
        </div>

        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Traffic Source Type
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.sellerOffer.allowedTrafficSourceType) }}
          </div>
        </div>

        <div class="form-group form-row">
          <CCol
            sm="3"
            class="pr-3 text-right font-weight-bold"
          >
            Affiliate Traffic Allowed
          </CCol>

          <CCol sm="3">
            {{ getSafe(() => requestDetails.sellerOffer.isAffiliateTrafficAllowed ? 'Yes' : 'No' ) }}
          </CCol>
        </div>
        

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3 pr-4 text-right font-weight-bold">Allowed Traffic Sources</label>
          <div class="col-sm-9 col-lg-6">
            <v-multiselect-listbox
              v-model="allowedTrafficSources"
              :options="requestDetails.sellerOffer.allowedTrafficSources"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search source"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No traffic source selected"
              hide-search-inputs
              read-only
              class="mt-2"
            />
          </div>
        </div>        
      </CCardBody>
    </CCard>

    <BidModifiers
      :parent-object="bidModifiersParentObject"
    />

    <ReviewNotes
      :parent-object="requestDetails"
    />

    <!-- Offer bid URL details -->
    <CModal
      title="Bidding URL"
      color="primary"
      :show.sync="showBiddingUrl"
      size="xl"
    >
      <SellerOfferBiddingDetails
        v-if="requestDetails && requestDetails.id"
        :offer-registration="requestDetails"
      />
    </CModal>
  </div>
</template>

<script>
import { required  } from 'vuelidate/lib/validators'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { dateTimeFormat } from '@/utils'
import { showSuccessMessage } from '@/notification-utils'
import { mapActions, mapState } from 'vuex'
import approveRejectModalMixin from '@/mixins/approve-reject-modal-mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'
import { thirdPartyTrafficOptions, offerRegistrationCssClassMapping } from '@/store/modules/sellerOfferRegistration.store'
import BidModifiers from '../BidModifiers/BidModifiers.vue'
import BidModifierParentType from '@/enums/BidModifierParentType.js'
import SellerAccountBasicDetails from '@/views/SellerAccountBasicDetails.vue'
import SellerOfferBiddingDetails from '@/views/SellerOffers/SellerOfferBiddingDetails.vue'

export default {
  name: 'SellerOfferApprovedRegistrationDetails',

  title: 'Seller Offer Registration',

  components: {
    ReviewNotes,
    BidModifiers,
    SellerAccountBasicDetails,
    SellerOfferBiddingDetails
  },
  
  mixins: [approveRejectModalMixin, copyToClipboardMixin, formGenericMixin],

  validations() {
    return {
      requestDetailsCopy: {
        thirdPartyTraffic: {
          required
        },

        approvedTrafficSources: {
          required
        },

        promotionalMethodsDetails: {
          required
        },

      },
    }
  },
  
  data() {
    return {
      type: 'SellerOfferRegistration',
      isAffiliateTrafficAllowed: undefined,
      approvedTrafficSources: [], //{''},
      isLoading: false,
      isOfferChangeLoading: false,
      requestDetailsCopy: {},
      showBiddingUrl: false
    }
  },

  computed: {
    ...mapState('sellerOfferRegistration', ['requestDetails']),

    thirdPartyTrafficOptions() {
      let options = [...thirdPartyTrafficOptions]

      options.unshift({
        value: null,
        label: 'Please select',
      })

      return options
    },

    requestIsReviewed() {
      return ['approved', 'rejected', 'withdrawn'].includes(
        this.requestDetails?.status?.toLowerCase()
      )
    },

    statusVariant() {
      return offerRegistrationCssClassMapping[this.requestDetails?.status?.toLowerCase()]
    },

    approvedTrafficSourcesForm: {
      get() {
        return this.approvedTrafficSources?.map(
          (ts) => ts.id
        )
      },

      set(value) {
        this.approvedTrafficSources = value.map((id) => {
          return {
            id,
          }
        })

        this.requestDetailsCopy.approvedTrafficSources = this.approvedTrafficSources
      },
    },

    allowedTrafficSources() {
      return this.requestDetails.sellerOffer.allowedTrafficSources.map((ts) => ts.id)
    },

    bidModifiersParentObject() {
      return {
        parentObjectType: BidModifierParentType.SELLER_OFFER_REGISTRATION,
        id: this.requestDetails.id,
        alwaysOn: false
      }
    }
  },

  watch: {
    requestDetails: {
      immediate: true,
      async handler(newValue) {
        this.requestDetailsCopy = {...newValue}

        await this.handleRequestDetailsChanged()
      }
    }
  },

  async created() {
    this.resetRegistrationRequestDetails()
    
    const sellerOfferRegistrationId = this.$route.params.registrationId

    await this.getRequestDetails(sellerOfferRegistrationId)
  },

  methods: {
    ...mapActions('sellerOfferRegistration', [
      'resetRegistrationRequestDetails',
      'getRequestDetails',
      'updateOfferRegistration'
    ]),

    handleThirdPartyTrafficChange() {
      if (this.requestDetailsCopy.thirdPartyTraffic === 'No') {
        this.requestDetailsCopy.isAffiliateTrafficApproved = false
      }
    },

    async submitOfferRegistrationForm() {
      this.submitted = true

      this.$v.requestDetailsCopy.$touch()

      if (!this.isValid) {
        return
      }

      try {
        this.isOfferChangeLoading = true

        await this.updateOfferRegistration({
          ...this.requestDetailsCopy,
          approvedTrafficSourcesIds: this.approvedTrafficSources.map((ts) => ts.id),
        })

        showSuccessMessage('Application successfully updated')
      } finally {
        this.isOfferChangeLoading = false
      }
    },

    async handleRequestDetailsChanged() {
      this.isAffiliateTrafficAllowed = this.requestDetails.sellerOffer.isAffiliateTrafficAllowed
      
      this.approvedTrafficSources = this.requestDetails.approvedTrafficSources
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('ID copied to clipboard')
    },

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },    
  }
}
</script>

<style lang="scss" scoped></style>
