import AccountsAPI from '@/api/accounts.api'
import GlobalSettingsAPI from '@/api/global-settings.api'
import SellerOfferApi from '@/api/sellerOffer.api'
import Vue from 'vue'

export const MIN_BUYER_ACCOUNT_BALANCE_RANGE = {
  min: -250_000,
  max: 10_000_000
}

export const SELLER_ACCOUNT_BALANCE_RANGE = {
  min: -250_000,
  max: 250_000
}

export const COMMON_ACCOUNT_REQUIREMENTS = {
  ADMIN_APPROVAL_REQUIREMENT: 'AdminApproval'
}

export const ACCOUNT_BILLING_DEFAULTS = {
  paymentTermsInDays: 15
}

/**
 * Get unment requirements
 * @param {Object.<string, bool>} requirementsMap 
 */
export function getUnmetRequirements(requirementsMap) {
  const unmetRequirements = []

  for (const requirement in requirementsMap) {
    const isRequirementMet = requirementsMap[requirement]

    if(!isRequirementMet) {
      unmetRequirements.push(requirement)
    }
  }

  return unmetRequirements
}

const state = {
  buyerAccounts: [],
  sellerAccounts: [],
  pendingBuyerAccounts: [],
  pendingSellerAccounts: [],

  buyerAccountDetails: null,
  buyerAccountRequirements: {},
  buyerAccountAutoPauseParameters: {},

  sellerAccountDetails: null,
  sellerAccountRequirements: {},
  buyerAccountsSearchString: '',
  sellerAccountsSearchString: '',
  allowedCategories: {},
  allowedTrafficSources: {},
  buyerOnboardingStatusCodes: [
    'Pending',
    'PendingDocumentSigning',   
    'Completed',   
  ],
  sellerOnboardingStatusCodes: [
    'Pending',
    'PaymentInfoAdded',
    'Completed',
  ],
  
  accountBalance: {
    cashBalance: null,
    holdingBalance: null,
    pendingBalance: null
  },

  advancePayRequests:[],

  accountUsers: {},

  globalBuyerDisputeFees: 0,

  globalSellerDisputeFees: 0,

  defaultBuyerAutoPauseParameters: {

  }
}

/**
* @type {import('vuex').GetterTree<typeof state>}
*/
const getters = {
  getAllowedTrafficSources: state => accountId => {
    return state.allowedTrafficSources[accountId]
  },
  getAllowedCategories: state => accountId => {
    return state.allowedCategories[accountId]
  },

  pendingBuyerAccountsWithNoAdminApproval(state) {
    return state.pendingBuyerAccounts
    .filter(acc => getUnmetRequirements(acc.requirements).includes(COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT))
    .map(acc => {
      return {
        ...acc,
        adminApprovalRequirement: !getUnmetRequirements(acc.requirements).includes(COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT),
      }
    })
  },

  pendingSellerAccountsWithNoAdminApproval(state) {
    return state.pendingSellerAccounts
    .filter(acc => getUnmetRequirements(acc.requirements).includes(COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT))
    .map(acc => {
      return {
        ...acc,
        adminApprovalRequirement: !getUnmetRequirements(acc.requirements).includes(COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT),
      }
    })
  },

  isDefaultAutoPauseParametersConfig(state) {
    return state.buyerAccountAutoPauseParameters == null 
          || (state.buyerAccountAutoPauseParameters.maxUnansweredCallsWithinDay === state.defaultBuyerAutoPauseParameters.maxUnansweredCallsWithinDay
            && state.buyerAccountAutoPauseParameters.maxUnansweredCallsWithinHours === state.defaultBuyerAutoPauseParameters.maxUnansweredCallsWithinHours
            && state.buyerAccountAutoPauseParameters.intervalOfHoursForUnansweredCalls === state.defaultBuyerAutoPauseParameters.intervalOfHoursForUnansweredCalls
          )
  }
}


/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  async getBuyerAccounts({ commit }) {
    const response = await AccountsAPI.getBuyerAccounts()

    commit('SET_BUYER_ACCOUNTS', response.data)
  },

  async searchBuyerAccounts({ commit }, searchString) {
    const response = await AccountsAPI.searchBuyerAccounts(searchString)
    commit('SET_BUYER_ACCOUNTS', response.data)
  },

  async getBuyerAccountDetails({ commit }, id) {
    commit('SET_BUYER_ACCOUNT_DETAILS', null)

    const response = await AccountsAPI.getBuyerAccountDetails(id)

    commit('SET_BUYER_ACCOUNT_DETAILS', response.data)
  },

  async getSellerAccounts({ commit }) {
    const response = await AccountsAPI.getSellerAccounts()

    commit('SET_SELLER_ACCOUNTS', response.data)
  },

  async searchSellerAccounts({ commit }, searchString) {
    const response = await AccountsAPI.searchSellerAccounts(searchString)
    commit('SET_SELLER_ACCOUNTS', response.data)
  },

  async getSellerAccountDetails({ commit, dispatch, state }, id) {
    if (state.sellerAccountDetails?.id === id) {
      return
    }

    commit('SET_SELLER_ACCOUNT_DETAILS', null)
    
    const response = await AccountsAPI.getSellerAccountDetails(id)

    commit('SET_SELLER_ACCOUNT_DETAILS', response.data)

    dispatch('getApprovedSellerOfferRequestsForAccount', id)
  },

  async getApprovedSellerOfferRequestsForAccount({ commit }, accountId) {
    const response = await SellerOfferApi.getApprovedSellerOfferRequestsForAccount(accountId)

    commit('SET_SELLER_ACCOUNT_OFFERS_REQUESTS', response.data)

    return response
  },

  async fetchAllowedCategoriesAndSources({ commit }, id) {
    const response = await AccountsAPI.fetchAllowedCategoriesAndSources(id)
    commit('SET_ALLOWED_CATEGORIES_AND_SOURCES', response.data)
  },

  async updateBuyerAccountBillingConfiguration(
    { commit },
    { accountId, billingConfig }
  ) {
    await AccountsAPI.updateBuyerAccountBillingConfig({ accountId, billingConfig })
    commit('SET_NEW_BUYER_BILLING_CONFIG', billingConfig)
  },

  async updateSellerAccountBillingConfiguration(
    { commit },
    { accountId, billingConfig }
  ) {
    await AccountsAPI.updateSellerAccountBillingConfig({ accountId, billingConfig })
    commit('SET_NEW_SELLER_BILLING_CONFIG', billingConfig)
  },

  async updateBuyerAccountTrafficConfig(
    { commit },
    { accountId, trafficConfig }
  ) {
    await AccountsAPI.updateBuyerAccountTrafficConfig({ accountId, trafficConfig })
    commit('SET_NEW_TRAFFIC_CONFIG_FOR_BUYER_ACCOUNT', trafficConfig)
  },

  async updateSellerAccountTrafficConfig(
    { commit },
    { accountId, trafficConfig }
  ) {
      await AccountsAPI.updateSellerAccountTrafficConfig({ accountId, trafficConfig })
      commit('SET_NEW_TRAFFIC_CONFIG_FOR_SELLER_ACCOUNT', trafficConfig)
  },

  async getApprovedSellerAccounts({ commit }) {
    const response = await AccountsAPI.getApprovedSellerAccounts()
    commit('SET_APPROVED_SELLER_ACCOUNTS', response.data)
  },

  async getApprovedBuyerAccounts({ commit }) {
    const response = await AccountsAPI.getApprovedBuyerAccounts()
    commit('SET_APPROVED_BUYER_ACCOUNTS', response.data)
  },

  async getSellerAccountBankInfoNotRedacted({ commit }, accountId) {
    const response = await AccountsAPI.getSellerAccountBankNotRedacted(accountId)

    commit('SET_SELLER_ACCOUNT_DETAILS_BANK_INFO_NOT_REDACTED', response.data)
  },

  async getSellerAccountSignedDocumentBundleFiles(context, { accountId, bundleId }) {
    return await AccountsAPI.getSellerAccountSignedDocumentBundleFiles(accountId, bundleId)
  },

  async getBuyerAccountSignedDocumentBundleFiles(context, { accountId, bundleId }) {
    return await AccountsAPI.getBuyerAccountSignedDocumentBundleFiles(accountId, bundleId)
  },

  async updateBuyerAccountDetails({ commit }, { accountId, updateObject }) {
    await AccountsAPI.updateBuyerAccountDetails({ accountId, updateObject })

    commit('SET_BUYER_ACCOUNT_DETAILS', updateObject)

  },

  async updateSellerAccountDetails({ commit }, { accountId, updateObject }) {
    await AccountsAPI.updateSellerAccountDetails({ accountId, updateObject })
    commit('SET_SELLER_ACCOUNT_DETAILS', updateObject)
    
  },

  async getBuyerAccountRequirements({commit}, accountId) {
    const response  = await AccountsAPI.getBuyerAccountRequirements(accountId)

    commit('SET_BUYER_ACCOUNT_REQUIREMENTS', response.data)

    return response
  },

  async getSellerAccountRequirements({commit}, accountId) {
    const response  = await AccountsAPI.getSellerAccountRequirements(accountId)

    commit('SET_SELLER_ACCOUNT_REQUIREMENTS', response.data)

    return response
  },

  async getAccountBalance({ commit }, accountId) {
    const response = await AccountsAPI.getAccountBalance(accountId)

    commit('SET_ACCOUNT_BALANCE', response.data)

    return response
  },

  async getAdvancePayRequests({ commit }, {pendingOnly}) {
    const response = await AccountsAPI.getAdvancePayRequests({pendingOnly: pendingOnly})    
    commit('SET_ADVANCE_PAY_REQUESTS', response.data || [])
  },

  async finalizeAdvancePayRequest(context, advancePayRequest) {
    const response = await AccountsAPI.finalizeAdvancePayRequest(advancePayRequest) 
    return response
  },

  async addFundsToBuyerAccount({ dispatch }, { accountId, amount, notes }) {
    const response = await AccountsAPI.addFundsToBuyerAccount(accountId, { amount, notes })
    dispatch('getAccountBalance', accountId)
    return response
  },

  async addFundsToSellerAccount({ dispatch }, { accountId, amount, notes }) {
    const response = await AccountsAPI.addFundsToSellerAccount(accountId, { amount, notes })
    dispatch('getAccountBalance', accountId)
    return response
  },

  async updateSellerBidSettings({ commit }, { accountId, bidPreferences }) {
    const response = await AccountsAPI.updateSellerBidPreferences({ accountId, bidPreferences })

    commit('UPDATE_SELLER_ACCOUNT_BID_PREFERENCES', bidPreferences)

    return response
  },

  async updateBuyerAccountBudgetCap({ dispatch }, { accountId, budget}) {
    await AccountsAPI.updateBuyerAccountBudgetCap(accountId, budget)

    await dispatch('getBuyerAccountDetails', accountId)
  },

  async updateBuyerRtbSettings({ dispatch }, { accountId, rtbSettings}) {
    await AccountsAPI.updateBuyerRtbSettings(accountId, rtbSettings)

    await dispatch('getBuyerAccountDetails', accountId)
  },

  async disableAccount({ commit }, {accountId, type, remarks}) {
    await AccountsAPI.disableAccount(accountId, type, {remarks: remarks})    

    if (type === 'buyer') {
      commit('SET_BUYER_ACCOUNT_ENABLED_FLAG', { accountId : accountId, enabled : false })
    }
    else if (type === 'seller') {
      commit('SET_SELLER_ACCOUNT_ENABLED_FLAG', { accountId : accountId, enabled : false })
    }
  },

  async enableAccount({ commit }, {accountId, type, remarks}) {
    await AccountsAPI.enableAccount(accountId, type, {remarks: remarks})

    if (type === 'buyer') {
      commit('SET_BUYER_ACCOUNT_ENABLED_FLAG', { accountId : accountId, enabled : true })
    }
    else if (type === 'seller') {
      commit('SET_SELLER_ACCOUNT_ENABLED_FLAG', { accountId : accountId, enabled : true })
    } 
  },

  async getPendingBuyerAccounts({ commit }) {
    const response = await AccountsAPI.getPendingBuyerAccounts()

    commit('SET_PENDING_BUYER_ACCOUNTS', response.data)
  },

  async getPendingSellerAccounts({ commit }) {
    const response = await AccountsAPI.getPendingSellerAccounts()

    commit('SET_PENDING_SELLER_ACCOUNTS', response.data)
  },

  async getAccountUsers({ commit }, accountId) {
    const response = await AccountsAPI.getAccountUsers(accountId)

    commit('SET_ACCOUNT_USERS', { accountId, users: response.data })

    return response
  },

  async createAccountUser({ dispatch }, { accountId, user }) {
    const response = await AccountsAPI.createAccountUser(accountId, {...user, accountId})

    dispatch('getAccountUsers', accountId) 

    return response
  },

  async markAccountAdminApprovalRequirement(context, { accountId, approve, accountType }) {
    const response = await AccountsAPI.markAccountAdminApprovalRequirement(accountId, accountType, approve)

    return response
  },

  async removeAccountUser({ dispatch }, { accountId, userId }) {
    const response = await AccountsAPI.removeAccountUser(accountId, userId)

    dispatch('getAccountUsers', accountId) 

    return response
  },

  async getGlobalBuyerDisputeFees({commit}) {
    try {
      const response = await GlobalSettingsAPI.getSettingValue({ type: 'decimal', settingId: 'BuyerDisputeFees' })
      commit('SET_GLOBAL_BUYER_DISPUTE_FEES', response.data)
    } catch(e) {
      if (e.response.status === 404) {
        commit('SET_GLOBAL_BUYER_DISPUTE_FEES', 0)
      } else {
        throw e
      }
    }

  },

  async getGlobalSellerDisputeFees({commit}) {
    try {
      const response = await GlobalSettingsAPI.getSettingValue({ type: 'decimal', settingId: 'SellerDisputeFees' })
      commit('SET_GLOBAL_SELLER_DISPUTE_FEES', response.data)
    } catch(e) {
      if (e.response.status === 404) {
        commit('SET_GLOBAL_SELLER_DISPUTE_FEES', 0)
      } else {
        throw e
      }
    }
  },

  async getBuyerAutoPauseParameters({ commit }, accountId) {
    const response = await AccountsAPI.getBuyerAutoPauseParameters(accountId)

    let autoPauseDetails = response.data.overriddenParameters ?? {
      intervalOfHoursForUnansweredCalls: response.data.defaultIntervalOfHoursForUnansweredCalls,
      maxUnansweredCallsWithinDay: response.data.defaultMaxUnansweredCallsWithinDay,
      maxUnansweredCallsWithinHours: response.data.defaultMaxUnansweredCallsWithinHours,
    }

    autoPauseDetails = {
      intervalOfHoursForUnansweredCalls: autoPauseDetails.intervalOfHoursForUnansweredCalls ?? response.data.defaultIntervalOfHoursForUnansweredCalls,
      maxUnansweredCallsWithinDay: autoPauseDetails.maxUnansweredCallsWithinDay ?? response.data.defaultMaxUnansweredCallsWithinDay,
      maxUnansweredCallsWithinHours: autoPauseDetails.maxUnansweredCallsWithinHours ?? response.data.defaultMaxUnansweredCallsWithinHours,
    }
    
    commit('SET_BUYER_ACCOUNT_AUTO_PAUSE_PARAMETERS', autoPauseDetails)

    commit('SET_DEFAULT_BUYER_AUTO_PAUSE_PARAMETERS', {
      intervalOfHoursForUnansweredCalls: response.data.defaultIntervalOfHoursForUnansweredCalls,
      maxUnansweredCallsWithinDay: response.data.defaultMaxUnansweredCallsWithinDay,
      maxUnansweredCallsWithinHours: response.data.defaultMaxUnansweredCallsWithinHours,
    })

    return response
  },

  async updateBuyerAutoPauseParameters(context, { accountId, autoPauseParameters }) {
    const response = await AccountsAPI.updateBuyerAutoPauseParameters(accountId, autoPauseParameters)

    return response
  }
}

/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = {
  SET_ADVANCE_PAY_REQUESTS(state, data) {
    state.advancePayRequests = data
  },

  SET_BUYER_ACCOUNTS(state, accounts) {
    state.buyerAccounts = accounts
  },

  SET_PENDING_BUYER_ACCOUNTS(state, accounts) {
    state.pendingBuyerAccounts = accounts
  },

  SET_BUYER_ACCOUNT_DETAILS(state, accountDetails) {
    state.buyerAccountDetails = accountDetails
  },

  SET_BUYER_ACCOUNT_REQUIREMENTS(state, requirements) {
    state.buyerAccountRequirements = requirements
  },

  SET_BUYER_ACCOUNT_AUTO_PAUSE_PARAMETERS(state, autoPauseParameters) {
    state.buyerAccountAutoPauseParameters = autoPauseParameters
  },

  SET_DEFAULT_BUYER_AUTO_PAUSE_PARAMETERS(state, autoPauseParameters) {
    state.defaultBuyerAutoPauseParameters = autoPauseParameters
  },

  SET_SELLER_ACCOUNT_REQUIREMENTS(state, requirements) {
    state.sellerAccountRequirements = requirements
  },

  SET_ACCOUNT_BALANCE(state, accountBalance) {
    state.accountBalance = accountBalance
  },

  SET_SELLER_ACCOUNTS(state, accounts) {
    state.sellerAccounts = accounts
  },

  SET_APPROVED_SELLER_ACCOUNTS(state, accounts) {
    state.sellerAccounts = accounts
  },

  SET_APPROVED_BUYER_ACCOUNTS(state, accounts) {
    state.buyerAccounts = accounts
  },

  SET_PENDING_SELLER_ACCOUNTS(state, accounts) {
    state.pendingSellerAccounts = accounts
  },

  SET_SELLER_ACCOUNT_DETAILS(state, accountDetails) {
    state.sellerAccountDetails = accountDetails
  },

  SET_SELLER_ACCOUNT_OFFERS_REQUESTS(state, offersRequests) {
    state.sellerAccountDetails = {
      ...(state.sellerAccountDetails ? state.sellerAccountDetails : {}),
      offersRequests
    }
  },

  RESET_ACCOUNT_DETAILS(state) {
    state.buyerAccountDetails = null
    state.sellerAccountDetails = null
  },

  SET_NEW_BUYER_BILLING_CONFIG(state, billingConfig) {
    state.buyerAccountDetails.billingConfiguration = billingConfig
  },

  SET_NEW_SELLER_BILLING_CONFIG(state, billingConfig) {
    state.sellerAccountDetails.billingConfiguration = billingConfig
  },

  SET_NEW_TRAFFIC_CONFIG_FOR_BUYER_ACCOUNT(state, trafficConfig) {
    state.buyerAccountDetails = {
      ...state.buyerAccountDetails,
      ...trafficConfig
    }
  },

  SET_NEW_TRAFFIC_CONFIG_FOR_SELLER_ACCOUNT(state, trafficConfig) {
    state.sellerAccountDetails = {
      ...state.sellerAccountDetails,
      ...trafficConfig
    }
  },

  SET_ALLOWED_CATEGORIES_AND_SOURCES(state, { accountId, allowedCategories, allowedTrafficSources }) {
    state.allowedCategories = {
      ...state.allowedCategories,
      [accountId]: allowedCategories
    }

    state.allowedTrafficSources = {
      ...state.allowedCategories,
      [accountId]: allowedTrafficSources
    }
  },

  SET_SELLER_ACCOUNT_DETAILS_BANK_INFO_NOT_REDACTED(state, bankInfo) {
    const maskedAccountNumber = state.sellerAccountDetails.billingConfiguration?.bankInfo?.maskedAccountNumber
    bankInfo.maskedAccountNumber = maskedAccountNumber

    state.sellerAccountDetails = {
      ...state.sellerAccountDetails,
      billingConfiguration: {
        ...state.sellerAccountDetails.billingConfiguration,
        bankInfo
      }
    }
  },

  SET_BUYER_ACCOUNT_SEARCH_STRING(state, searchString) {
    state.buyerAccountsSearchString = searchString
  },

  SET_SELLER_ACCOUNT_SEARCH_STRING(state, searchString) {
    state.sellerAccountsSearchString = searchString
  },

  UPDATE_SELLER_ACCOUNT_BID_PREFERENCES(state, bidPreferences) {
    state.sellerAccountDetails.bidPreferences = bidPreferences
  },

  SET_BUYER_ACCOUNT_ENABLED_FLAG(state, {accountId, enabled}) {
    const buyerAccount = state.buyerAccounts.find(item => item.id === accountId)
    buyerAccount.enabled = enabled

    state.buyerAccounts = {
      ...state.buyerAccounts.map(item => item.id !== accountId 
        ? item 
        : {...item, ...buyerAccount})
    }    
  },

  SET_SELLER_ACCOUNT_ENABLED_FLAG(state, {accountId, enabled}) {
    const sellerAccount = state.sellerAccounts.find(item => item.id === accountId)
    sellerAccount.enabled = enabled

    state.sellerAccounts = {
      ...state.sellerAccounts.map(item => item.id !== accountId 
        ? item 
        : {...item, ...sellerAccount})
    }    
  },

  SET_ACCOUNT_USERS(state, { accountId, users }) {
    Vue.set(state.accountUsers, accountId, users.map(u => ({
      ...u,
      name: u.name ?? `${u.firstName} ${u.lastName}`
    })))
  },

  SET_GLOBAL_BUYER_DISPUTE_FEES(state, fees) {
    state.globalBuyerDisputeFees = fees
  },

  SET_GLOBAL_SELLER_DISPUTE_FEES(state, fees) {
    state.globalSellerDisputeFees = fees
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}