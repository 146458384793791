<template>
  <CCard>
    <CCardHeader>
      <div class="d-flex">
        Edit Account Billing

        <CButton
          color="info"
          class="ml-auto"
          size="sm"
          :to="`/dashboard/accounts/seller/${accountId}/payments`"
          target="_blank"
        >
          View Payment History

          <CIcon
            name="cil-external-link"
            size="custom"
            class="ml-1"
          />
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <dl class="row">
          <dt class="col-sm-3 font-weight-normal">
            Pending Earnings
          </dt>
          <dd class="col-sm-9 d-flex align-items-center mb-0">
            <h6 class="mr-3">
              {{ getSafe(() => formatAmount(currentBalance, 0)) }}
            </h6>

            <router-link
              :to="{ name: 'SellerAccountAddFunds', params: { accountId: accountId} }"
            >
              <CButton
                size="sm"
                color="success"
                style="position: relative; bottom: 3px;"
              >
                Add Funds
              </CButton>
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3 font-weight-normal">
            Received Payouts
          </dt>
          <dd class="col-sm-9 d-flex align-items-center mb-0">
            <h6 class="mr-3">
              {{ getSafe(() => formatAmount(accountBalance.cashBalance, 0)) }}
            </h6>
          </dd>
        </dl>
        <CSelect
          :value.sync="accountBillingConfigLocal.billingCycle"
          label="Billing Period"
          horizontal
          :options="billingCycles"
          placeholder="Please select"
          :is-valid="checkIfValid('billingCycle')"
          :invalid-feedback="billingCycleInvalid"
        />

        <CInput
          v-model="$v.accountBillingConfigLocal.paymentTermsInDays.$model"
          :value.sync="accountBillingConfigLocal.paymentTermsInDays"
          type="number"
          label="Payment Terms"
          name="paymentTerms"
          placeholder="Payment terms (in days)"
          horizontal
          :is-valid="checkIfValid('paymentTermsInDays')"
          :invalid-feedback="paymentTermsInDaysInvalid"
        />

        <div
          v-if="false"
          class="form-group form-row"
        >
          <label class="col-form-label col-sm-3">
            Custom Dispute Fees
          </label>

          <CSwitch
            label-off="No"
            label-on="Yes"
            shape="pill"
            color="primary"
            data-cy="pre-pay-checkbox"
            horizontal
            add-label-classes=""
            add-input-classes="w-25"
            size="lg"
            :checked.sync="accountBillingConfigLocal.setCustomDisputeFees"
            @update:checked="onChangeDisputeFeesSetting"
          />
        </div>

        <CInput
          v-if="accountBillingConfigLocal.setCustomDisputeFees"
          :value.sync="$v.accountBillingConfigLocal.disputeFees.$model"
          type="number"
          label="Dispute Fees"
          name="Dispute Fees"
          placeholder="Enter fees"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-3 col-12'}"
          :is-valid="checkIfValid('disputeFees')"
          invalid-feedback="Please enter dispute fees greater than $0"
        />

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <CButton
              type="submit"
              size="sm"
              color="primary"
              @click="onFormSubmit"
            >
              Change
            </CButton>
          </CCol>
        </div>
      </CForm>
    </CCardBody>

    <CModal
      title="Confirm change billing"
      color="success"
      :show.sync="warningBillingChangeModal"
    >
      Are you sure you want to edit the billing configuration?
      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="onConfirmSubmit"
        >
          Change
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningBillingChangeModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import { ACCOUNT_BILLING_DEFAULTS } from '@/store/modules/accounts.store'
import { formatAmount } from '@/utils'
import formMixins from '@/mixins/form-mixins'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SellerBillingDetails',

  mixins: [formMixins],

  props: {
    accountId: {
      type: String,
      default: ''
    },

    accountBillingConfig: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  validations() {
    return {
      accountBillingConfigLocal: {
        billingCycle: {
          required
        },

        paymentTermsInDays: {
          minValue: 14,
          required
        },

        disputeFees: {
          minValue: minValue(0)
        }
      }
    }
  },

  data() {
    return {
      accountBillingConfigLocal: {
        billingCycle: null,
        paymentTermsInDays: ACCOUNT_BILLING_DEFAULTS.paymentTermsInDays,
      },

      warningBillingChangeModal: false,
      isLoading: false,
      paymentTermsInDaysInvalid: `Please enter a valid value, it should be between 14 and 90`,
      billingCycleInvalid: `Please select a valid value from the list`,
    }
  },

  
  computed: {
    ...mapGetters('auth', [
      'isFinancialManager',
      'isSuperAdmin'
    ]),

    ...mapState(['billingCycles']),

    ...mapState('accounts', {
      accountBalance: 'accountBalance',
      globalSellerDisputeFees: 'globalSellerDisputeFees'
    }),

    isValid() {
      return !this.$v.$invalid
    },

    currentBalance() {
      return this.accountBalance.pendingBalance + this.accountBalance.holdingBalance
    },
  },

  watch: {
    accountBillingConfig: {
      immediate: true,
      handler(newValue) {
        this.accountBillingConfigLocal = newValue
          ? { ...newValue, setCustomDisputeFees: newValue?.disputeFees != null }
          : {
              billingCycle: null,
              paymentTermsInDays: ACCOUNT_BILLING_DEFAULTS.paymentTermsInDays,
              setCustomDisputeFees: false
            }
      }
    }
  },

  async created () {
    await this.getGlobalSellerDisputeFees()

    await this.getAccountBalance(this.accountId)

    this.accountBillingConfigLocal.setCustomDisputeFees = this.accountBillingConfigLocal?.disputeFees != null
  },

  methods: {
    ...mapActions('accounts', [
      'updateSellerAccountBillingConfiguration',
      'getAccountBalance',
      'getGlobalSellerDisputeFees'
    ]),

    onFormSubmit() {
      this.$v.accountBillingConfigLocal.$touch()

      if (this.isValid) {
        this.warningBillingChangeModal = true
      }
    },

    async onConfirmSubmit() {
      if (this.isValid) {
        this.isLoading = true

        try {
          await this.updateSellerAccountBillingConfiguration({
            accountId: this.accountId,
            billingConfig: this.accountBillingConfigLocal
          })
          this.warningBillingChangeModal = false
          showSuccessMessage('Billing configuration updated successfully')
        } catch (error) {
          // Handle error
        } finally {
          this.isLoading = false
        }
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v.accountBillingConfigLocal[fieldName]

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    formatAmount,

    onChangeDisputeFeesSetting() {
      if (this.accountBillingConfigLocal.setCustomDisputeFees) {
        this.accountBillingConfigLocal.disputeFees = this.globalSellerDisputeFees
      } else {
        this.accountBillingConfigLocal.disputeFees = null
      }
    }

  },
}
</script>
<style>
  
</style>