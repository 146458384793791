<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Create Category
      </CCardHeader>
      <CCardBody>
        <CForm v-if="parentCategories && parentCategories.length">
          <CInput
            id="name"
            v-model="$v.form.name.$model"
            data-cy="input-name"
            label="Name"
            horizontal
            placeholder="Category Name"
            :is-valid="checkIfValid(['form', 'name'])"
            :invalid-feedback="cannotBeBlank('Name')"
          />

          <CSelect
            id="parent-category"
            data-cy="input-parent-category"
            label="Parent Category"
            horizontal
            :value.sync="$v.form.parentCategoryId.$model"
            :options="
              parentCategories.map(p => ({ label: p.name, value: p.id }))
            "
            placeholder="Please select"
            :is-valid="checkIfValid(['form', 'parentCategoryId'])"
            :invalid-feedback="pleaseSelect('parent category')"
          />

          <div class="form-group form-row">
            <label class="col-form-label col-sm-3 pr-4">Languages</label>

            <div class="col-sm-5">
              <v-multiselect-listbox
                v-if="languages.length"
                v-model="$v.form.languages.$model"
                :options="languages"
                :reduce-display-property="(option) => option.name"
                :reduce-value-property="(option) => option.id"
                search-input-class="custom-form-input custom-form-input--sm"
                search-options-placeholder="Search Language"
                selected-options-placeholder="Search selected"
                selected-no-options-text="No language selected"
              />

              <div
                v-if="isFormEdited && $v.form.languages.$invalid"
                class="invalid-feedback d-flex mb-3 mt-2"
              >
                Please select language
              </div>
            </div>
          </div>

          <CTextarea
            id="description"
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />
          <DatePickerInput
            id="expiration-date"
            v-model="form.expirationDate"
            :min-date="new Date()"
            data-cy="input-expiration-date"
            label="Expiration Date"
            placeholder="Choose expiration date"
            horizontal
            readonly
          />
          <CSelect
            id="status"
            data-cy="input-status"
            label="Status"
            horizontal
            :value.sync="$v.form.status.$model"
            :options="statusCodesForNewEntry"
            placeholder="Please select"
            :is-valid="checkIfValid(['form', 'status'])"
            :invalid-feedback="pleaseSelect('status')"
          />        

          <legend>Bid Preferences</legend>          

          <CInput
            v-model="$v.form.bidPreferences.minProfitAmount.$model"
            type="number"
            label="Minimum Profit Amount"
            name="Minimum Profit Amount"
            placeholder="Minimum Profit Amount"
            description="amount in USD"
            horizontal
            :is-valid="checkIfValid(['form', 'bidPreferences', 'minProfitAmount'])"
            :invalid-feedback="minProfitAmountInvalid"
          />

          <CInput
            v-model="$v.form.bidPreferences.maxBid.$model"
            type="number"
            label="Max Bid"
            name="Max Bid"
            placeholder="Max Bid"
            description="amount in USD"
            horizontal
            :is-valid="checkIfValid(['form', 'bidPreferences', 'maxBid'])"
            :invalid-feedback="maxBidInvalid"
          />

          <CInput
            v-model="$v.form.bidPreferences.minBid.$model"
            type="number"
            label="Min Bid"
            name="Min Bid"
            placeholder="Min Bid"
            description="amount in USD"
            horizontal
            :is-valid="checkIfValid(['form', 'bidPreferences', 'minBid'])"
            :invalid-feedback="minBidInvalid"
          />


          <legend>Adjustment of payout minimum required duration</legend>

          <CRow
            form
            class="form-group"
          >
            <CCol
              tag="label"
              sm="3"
              class="col-form-label"
            >
              Set Maximum Required Duration
            </CCol>
            <CCol
              sm="9"
              class="mt-1"
            >
              <CSwitch
                shape="pill"
                :checked.sync="setMaxRequiredDuration"
                color="primary"
                @update:checked="onToggleSetMaxRequiredDuration"
              />
            </CCol>
          </CRow>

          <CInput
            v-if="setMaxRequiredDuration"
            v-model.number="$v.form.maxDurationRequiredForConversionInSeconds.$model"
            type="number"
            label="Maximum Required Duration in seconds"
            name="Maximum Required Duration"
            placeholder="Enter duration in seconds"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-3 col-12'}"
            :is-valid="checkIfValid(['form', 'maxDurationRequiredForConversionInSeconds'])"
            :description="form.maxDurationRequiredForConversionInSeconds && `${(form.maxDurationRequiredForConversionInSeconds/60).toFixed(2)} minutes`"
            invalid-feedback="Maximum Required Duration should be greater than 1 second"
            @change="form.maxDurationRequiredForConversionInSeconds = parseInt(form.maxDurationRequiredForConversionInSeconds) || 1"
          />

          <CInput
            v-model="$v.form.callRequiredDurationAdjustment.minimumIncreaseInSeconds.$model"
            type="number"
            label="Minimum number of seconds to increase by"
            name="Minimum number of seconds to increase by"
            placeholder="Minimum number of seconds to increase by"
            horizontal
            :is-valid="checkIfValid(['form', 'callRequiredDurationAdjustment', 'minimumIncreaseInSeconds'])"
            :invalid-feedback="minimumIncreaseInSecondsInvalid"
          />

          <CInput
            v-model="$v.form.callRequiredDurationAdjustment.maximumIncreaseInSeconds.$model"
            type="number"
            label="Maximum number of seconds to increase by"
            name="Maximum number of seconds to increase by"
            placeholder="Maximum number of seconds to increase by"
            horizontal
            :is-valid="checkIfValid(['form', 'callRequiredDurationAdjustment', 'maximumIncreaseInSeconds'])"
            :invalid-feedback="maximumIncreaseInSecondsInvalid"
          />

          <legend>Flags</legend>

          <div>
            <div
              v-if="flags && flags.length > 0"
            >          
              <CRow
                v-for="(flag, index) in flags"
                :key="`custom-header-${index}`"
                form
                class="form-group"
              >
                <CInputCheckbox
                  :label="flag.name"
                  :checked.sync="flag.checked"
                  horizontal
                  class="mt-2 ml-1"
                />
              </CRow>
            </div>
          </div>
        </CForm>
      </CCardBody>

      <CCardFooter>
        <CButton
          :disabled="isLoading"
          data-cy="button-submit"
          type="submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitCreateForm({ createFn: create, entity: 'categories' })
          "
        >
          <CSpinner
            v-if="isLoading"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>

        <CButton
          data-cy="button-back"
          class="ml-2"
          :disabled="isLoading"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('categories')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import { minValue, required, between  } from 'vuelidate/lib/validators'

import formMixins from '@/mixins/form-mixins'
import { MAX_BID_MINIMUM, MIN_BID_MINIMUM, MIN_PROFIT_AMOUNT_MINIMUM,  
  MINIMUM_INCREASE_IN_SECONDS, MAXIMUM_INCREASE_IN_SECONDS, CATEGORY_FLAGS } from '@/store/modules/category.store'

export default {
  name: 'CategoryCreate',
  
  mixins: [formMixins],

  validations() {
    return {
      form: {
        name: {
          required
        },

        parentCategoryId: {
          required
        },

        status: {
          required
        },

        languages: {
          required
        },

        bidPreferences: {         

          minProfitAmount: {
            required,
            minValue: minValue(MIN_PROFIT_AMOUNT_MINIMUM)
          },

          maxBid: {
            required,
            minValue: minValue(MAX_BID_MINIMUM)
          },

          minBid: {
            required,
            minValue: minValue(MIN_BID_MINIMUM)
          }
        },

        callRequiredDurationAdjustment: {
          minimumIncreaseInSeconds: {
            required,
            between: between(MINIMUM_INCREASE_IN_SECONDS, MAXIMUM_INCREASE_IN_SECONDS)
          },

          maximumIncreaseInSeconds: {
            required,
            between: between(this.form.callRequiredDurationAdjustment.minimumIncreaseInSeconds, MAXIMUM_INCREASE_IN_SECONDS)
          }
        },

        maxDurationRequiredForConversionInSeconds: {
          minValue() {
            if (this.setMaxRequiredDuration) {
              return minValue(1)
            }

            return minValue(0)
          }
        }
      }
    }
  },

  data() {
    return {
      form: {
        name: '',
        description: '',
        expirationDate: null,
        status: null,
        parentCategoryId: null,
        bidPreferences: {         
          minProfitAmount: 1,
          maxBid: 250,
          minBid: 0
        },
        callRequiredDurationAdjustment: {
          minimumIncreaseInSeconds: 0,
          maximumIncreaseInSeconds : 0
        },
        maxDurationRequiredForConversionInSeconds: 0,
        languages: [],
        flags: []
      },
      flags:[],

      setMaxRequiredDuration: false,

      minProfitAmountInvalid: `Minimum Profit Amount should be more than $${MIN_PROFIT_AMOUNT_MINIMUM}`,

      maxBidInvalid: `Max Bid should be more than $${MAX_BID_MINIMUM}`,

      minBidInvalid: `Min Bid may not be lower than $${MIN_BID_MINIMUM} or greater than $${MAX_BID_MINIMUM}`,

      minimumIncreaseInSecondsInvalid: `Minimum increase in seconds should be between ${MINIMUM_INCREASE_IN_SECONDS} and ${MAXIMUM_INCREASE_IN_SECONDS}`,

      maximumIncreaseInSecondsInvalid: `Maximum increase in seconds should be between minimum increase in seconds and ${MAXIMUM_INCREASE_IN_SECONDS}`
    }
  },
  computed: {
    ...mapState(['statusCodesForNewEntry']),
    ...mapState('parentCategory', ['parentCategories']),
    ...mapState('language', ['languages'])
  },
  async created() {
    await this.listParentCategories()
    await this.listLanguages()
    this.setFlags()
  },
  methods: {
    ...mapActions('parentCategory', { listParentCategories: 'list' }),
    ...mapActions('category', ['create']),
    ...mapActions('language', { listLanguages: 'list' }),

    onToggleSetMaxRequiredDuration(setMaxRequiredDuration) {
      if (setMaxRequiredDuration) {
        this.form.maxDurationRequiredForConversionInSeconds = 60 * 10 // 10 mins
      } else {
        this.form.maxDurationRequiredForConversionInSeconds = 0
      }
    },

    setFlags() {
      const defaultFlags = CATEGORY_FLAGS.map(x => {
        return {
          name: x, 
          checked: false
        }
      })    

      this.flags = defaultFlags      
    },

    mapToFormFlags() {      
      this.form.flags = this.flags.filter(x => x.checked).map(x => x.name)
    },
  }
}
</script>

<style lang="scss" scoped></style>
